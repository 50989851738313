// package: ld.services.hosted.v1alpha1
// file: ld/services/hostedapi/v1alpha1/deploymentcredentials.proto

var ld_services_hostedapi_v1alpha1_deploymentcredentials_pb = require("../../../../ld/services/hostedapi/v1alpha1/deploymentcredentials_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var DeploymentDoltCredentialsService = (function () {
  function DeploymentDoltCredentialsService() {}
  DeploymentDoltCredentialsService.serviceName = "ld.services.hosted.v1alpha1.DeploymentDoltCredentialsService";
  return DeploymentDoltCredentialsService;
}());

DeploymentDoltCredentialsService.GetDeploymentDoltCredentials = {
  methodName: "GetDeploymentDoltCredentials",
  service: DeploymentDoltCredentialsService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deploymentcredentials_pb.GetDeploymentDoltCredentialsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deploymentcredentials_pb.GetDeploymentDoltCredentialsResponse
};

DeploymentDoltCredentialsService.CreateDeploymentDoltCredentials = {
  methodName: "CreateDeploymentDoltCredentials",
  service: DeploymentDoltCredentialsService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deploymentcredentials_pb.CreateDeploymentDoltCredentialsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deploymentcredentials_pb.CreateDeploymentDoltCredentialsResponse
};

DeploymentDoltCredentialsService.DeleteDeploymentDoltCredentials = {
  methodName: "DeleteDeploymentDoltCredentials",
  service: DeploymentDoltCredentialsService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deploymentcredentials_pb.DeleteDeploymentDoltCredentialsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

DeploymentDoltCredentialsService.RerollDeploymentDoltCredentials = {
  methodName: "RerollDeploymentDoltCredentials",
  service: DeploymentDoltCredentialsService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deploymentcredentials_pb.RerollDeploymentDoltCredentialsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deploymentcredentials_pb.RerollDeploymentDoltCredentialsResponse
};

exports.DeploymentDoltCredentialsService = DeploymentDoltCredentialsService;

function DeploymentDoltCredentialsServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

DeploymentDoltCredentialsServiceClient.prototype.getDeploymentDoltCredentials = function getDeploymentDoltCredentials(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentDoltCredentialsService.GetDeploymentDoltCredentials, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentDoltCredentialsServiceClient.prototype.createDeploymentDoltCredentials = function createDeploymentDoltCredentials(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentDoltCredentialsService.CreateDeploymentDoltCredentials, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentDoltCredentialsServiceClient.prototype.deleteDeploymentDoltCredentials = function deleteDeploymentDoltCredentials(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentDoltCredentialsService.DeleteDeploymentDoltCredentials, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentDoltCredentialsServiceClient.prototype.rerollDeploymentDoltCredentials = function rerollDeploymentDoltCredentials(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentDoltCredentialsService.RerollDeploymentDoltCredentials, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.DeploymentDoltCredentialsServiceClient = DeploymentDoltCredentialsServiceClient;

