// source: ld/services/hostedapi/v1alpha1/deployments.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_money_pb = require('../../../../google/type/money_pb.js');
goog.object.extend(proto, google_type_money_pb);
var google_type_dayofweek_pb = require('../../../../google/type/dayofweek_pb.js');
goog.object.extend(proto, google_type_dayofweek_pb);
var ld_rpc_request_logging_pb = require('../../../../ld/rpc/request_logging_pb.js');
goog.object.extend(proto, ld_rpc_request_logging_pb);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.Application', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.BoolOverrideValidation', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CalcCostRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CalcCostResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CloudProvider', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ClusterType', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CollaboratorPermissionLevel', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.Cost', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateBackupRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateBackupResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.Dashboard', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.Deployment', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeploymentCollaborator', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeploymentFlag', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeploymentInstance', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeploymentInstanceState', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeploymentRole', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeploymentState', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.EnumOverrideValidation', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetDashboardsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetDashboardsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetDeploymentRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetDeploymentResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetGraphDataRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetGraphDataResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetGraphsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetGraphsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetLogsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetLogsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.Graph', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GraphData', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.InstanceType', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.IntOverrideValidation', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListDeploymentsItem', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.LogLine', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.Override', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.Override.ValidationCase', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.OverrideList', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.OverrideTypes', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.PrivateLinkConfig', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.PrivateLinkEndpointState', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.RebootInstanceRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.RebootInstanceResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.RestartDoltRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.RestartDoltResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ServiceWindow', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.StorageOption', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.StringOverrideValidation', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.TimePeriod', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.UpdateDoltRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.UpdateDoltResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.WorkbenchSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.Cost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.Cost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.Cost.displayName = 'proto.ld.services.hosted.v1alpha1.Cost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.InstanceType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.InstanceType.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.InstanceType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.InstanceType.displayName = 'proto.ld.services.hosted.v1alpha1.InstanceType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.StorageOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.StorageOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.StorageOption.displayName = 'proto.ld.services.hosted.v1alpha1.StorageOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CalcCostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CalcCostRequest.displayName = 'proto.ld.services.hosted.v1alpha1.CalcCostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CalcCostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.CalcCostResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CalcCostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CalcCostResponse.displayName = 'proto.ld.services.hosted.v1alpha1.CalcCostResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.displayName = 'proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse.displayName = 'proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.Deployment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.Deployment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.Deployment.displayName = 'proto.ld.services.hosted.v1alpha1.Deployment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.PrivateLinkConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.displayName = 'proto.ld.services.hosted.v1alpha1.PrivateLinkConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.displayName = 'proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.displayName = 'proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.displayName = 'proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse.displayName = 'proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest.displayName = 'proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse.displayName = 'proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListDeploymentsItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.displayName = 'proto.ld.services.hosted.v1alpha1.ListDeploymentsItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetDeploymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetDeploymentRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetDeploymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetDeploymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetDeploymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.displayName = 'proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.DeploymentInstance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.DeploymentInstance.displayName = 'proto.ld.services.hosted.v1alpha1.DeploymentInstance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest.displayName = 'proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.displayName = 'proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetLogsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.LogLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.LogLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.LogLine.displayName = 'proto.ld.services.hosted.v1alpha1.LogLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.GetLogsResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetLogsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetLogsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetLogsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetDashboardsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetDashboardsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetDashboardsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.Dashboard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.Dashboard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.Dashboard.displayName = 'proto.ld.services.hosted.v1alpha1.Dashboard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetDashboardsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetDashboardsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetGraphsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetGraphsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetGraphsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.Graph = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.Graph, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.Graph.displayName = 'proto.ld.services.hosted.v1alpha1.Graph';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetGraphsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.GetGraphsResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetGraphsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetGraphsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetGraphsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetGraphDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetGraphDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GraphData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.GraphData.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GraphData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GraphData.displayName = 'proto.ld.services.hosted.v1alpha1.GraphData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetGraphDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetGraphDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.DeploymentCollaborator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.displayName = 'proto.ld.services.hosted.v1alpha1.DeploymentCollaborator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.displayName = 'proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.displayName = 'proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.displayName = 'proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.IntOverrideValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.IntOverrideValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.IntOverrideValidation.displayName = 'proto.ld.services.hosted.v1alpha1.IntOverrideValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.StringOverrideValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.StringOverrideValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.StringOverrideValidation.displayName = 'proto.ld.services.hosted.v1alpha1.StringOverrideValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.BoolOverrideValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.BoolOverrideValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.BoolOverrideValidation.displayName = 'proto.ld.services.hosted.v1alpha1.BoolOverrideValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.EnumOverrideValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.EnumOverrideValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.displayName = 'proto.ld.services.hosted.v1alpha1.EnumOverrideValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.Override = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.ld.services.hosted.v1alpha1.Override.oneofGroups_);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.Override, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.Override.displayName = 'proto.ld.services.hosted.v1alpha1.Override';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.OverrideList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.OverrideList.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.OverrideList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.OverrideList.displayName = 'proto.ld.services.hosted.v1alpha1.OverrideList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.displayName = 'proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse.displayName = 'proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ServiceWindow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ServiceWindow.displayName = 'proto.ld.services.hosted.v1alpha1.ServiceWindow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.displayName = 'proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.displayName = 'proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse.displayName = 'proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest.displayName = 'proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse.displayName = 'proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.RebootInstanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.RebootInstanceRequest.displayName = 'proto.ld.services.hosted.v1alpha1.RebootInstanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.RebootInstanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.RebootInstanceResponse.displayName = 'proto.ld.services.hosted.v1alpha1.RebootInstanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.RestartDoltRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.RestartDoltRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.RestartDoltRequest.displayName = 'proto.ld.services.hosted.v1alpha1.RestartDoltRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.RestartDoltResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.RestartDoltResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.RestartDoltResponse.displayName = 'proto.ld.services.hosted.v1alpha1.RestartDoltResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateBackupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateBackupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateBackupRequest.displayName = 'proto.ld.services.hosted.v1alpha1.CreateBackupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateBackupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateBackupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateBackupResponse.displayName = 'proto.ld.services.hosted.v1alpha1.CreateBackupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.UpdateDoltRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.displayName = 'proto.ld.services.hosted.v1alpha1.UpdateDoltRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.UpdateDoltResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.UpdateDoltResponse.displayName = 'proto.ld.services.hosted.v1alpha1.UpdateDoltResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.displayName = 'proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse.displayName = 'proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.displayName = 'proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse.displayName = 'proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.WorkbenchSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.WorkbenchSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.WorkbenchSettings.displayName = 'proto.ld.services.hosted.v1alpha1.WorkbenchSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest.displayName = 'proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse.displayName = 'proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest.displayName = 'proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse.displayName = 'proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.Cost.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.Cost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.Cost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.Cost.toObject = function(includeInstance, msg) {
  var f, obj = {
amount: (f = msg.getAmount()) && google_type_money_pb.Money.toObject(includeInstance, f),
period: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.Cost}
 */
proto.ld.services.hosted.v1alpha1.Cost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.Cost;
  return proto.ld.services.hosted.v1alpha1.Cost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.Cost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.Cost}
 */
proto.ld.services.hosted.v1alpha1.Cost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_money_pb.Money;
      reader.readMessage(value,google_type_money_pb.Money.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.TimePeriod} */ (reader.readEnum());
      msg.setPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.Cost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.Cost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.Cost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.Cost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getPeriod();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional google.type.Money amount = 1;
 * @return {?proto.google.type.Money}
 */
proto.ld.services.hosted.v1alpha1.Cost.prototype.getAmount = function() {
  return /** @type{?proto.google.type.Money} */ (
    jspb.Message.getWrapperField(this, google_type_money_pb.Money, 1));
};


/**
 * @param {?proto.google.type.Money|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.Cost} returns this
*/
proto.ld.services.hosted.v1alpha1.Cost.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.Cost} returns this
 */
proto.ld.services.hosted.v1alpha1.Cost.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.Cost.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TimePeriod period = 2;
 * @return {!proto.ld.services.hosted.v1alpha1.TimePeriod}
 */
proto.ld.services.hosted.v1alpha1.Cost.prototype.getPeriod = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.TimePeriod} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.TimePeriod} value
 * @return {!proto.ld.services.hosted.v1alpha1.Cost} returns this
 */
proto.ld.services.hosted.v1alpha1.Cost.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
cloud: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest;
  return proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (reader.readEnum());
      msg.setCloud(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloud();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional CloudProvider cloud = 1;
 * @return {!proto.ld.services.hosted.v1alpha1.CloudProvider}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest.prototype.getCloud = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.CloudProvider} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesRequest.prototype.setCloud = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
cloud: jspb.Message.getFieldWithDefault(msg, 1, 0),
supportedZonesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse;
  return proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (reader.readEnum());
      msg.setCloud(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSupportedZones(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloud();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSupportedZonesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional CloudProvider cloud = 1;
 * @return {!proto.ld.services.hosted.v1alpha1.CloudProvider}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.prototype.getCloud = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.CloudProvider} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.prototype.setCloud = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string supported_zones = 2;
 * @return {!Array<string>}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.prototype.getSupportedZonesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.prototype.setSupportedZonesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.prototype.addSupportedZones = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetSupportedZonesResponse.prototype.clearSupportedZonesList = function() {
  return this.setSupportedZonesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
cloud: jspb.Message.getFieldWithDefault(msg, 1, 0),
zone: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest;
  return proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (reader.readEnum());
      msg.setCloud(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloud();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional CloudProvider cloud = 1;
 * @return {!proto.ld.services.hosted.v1alpha1.CloudProvider}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.prototype.getCloud = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.CloudProvider} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.prototype.setCloud = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string zone = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesRequest.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.InstanceType.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.InstanceType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.InstanceType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.InstanceType.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
name: jspb.Message.getFieldWithDefault(msg, 2, ""),
cost: jspb.Message.getFieldWithDefault(msg, 3, 0),
cpus: jspb.Message.getFieldWithDefault(msg, 4, 0),
mem: jspb.Message.getFieldWithDefault(msg, 5, 0),
network: jspb.Message.getFieldWithDefault(msg, 6, ""),
description: jspb.Message.getFieldWithDefault(msg, 7, ""),
costsList: jspb.Message.toObjectList(msg.getCostsList(),
    proto.ld.services.hosted.v1alpha1.Cost.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.InstanceType}
 */
proto.ld.services.hosted.v1alpha1.InstanceType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.InstanceType;
  return proto.ld.services.hosted.v1alpha1.InstanceType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.InstanceType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.InstanceType}
 */
proto.ld.services.hosted.v1alpha1.InstanceType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCost(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCpus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMem(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetwork(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = new proto.ld.services.hosted.v1alpha1.Cost;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.Cost.deserializeBinaryFromReader);
      msg.addCosts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.InstanceType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.InstanceType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.InstanceType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCost();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getCpus();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMem();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getNetwork();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.ld.services.hosted.v1alpha1.Cost.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.InstanceType} returns this
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.InstanceType} returns this
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 cost = 3;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.InstanceType} returns this
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.setCost = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 cpus = 4;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.getCpus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.InstanceType} returns this
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.setCpus = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 mem = 5;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.getMem = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.InstanceType} returns this
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.setMem = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string network = 6;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.getNetwork = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.InstanceType} returns this
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.setNetwork = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.InstanceType} returns this
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated Cost costs = 8;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.Cost>}
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.getCostsList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.Cost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.Cost, 8));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.Cost>} value
 * @return {!proto.ld.services.hosted.v1alpha1.InstanceType} returns this
*/
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.setCostsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.Cost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.Cost}
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.addCosts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.ld.services.hosted.v1alpha1.Cost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.InstanceType} returns this
 */
proto.ld.services.hosted.v1alpha1.InstanceType.prototype.clearCostsList = function() {
  return this.setCostsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
cloud: jspb.Message.getFieldWithDefault(msg, 1, 0),
zone: jspb.Message.getFieldWithDefault(msg, 2, ""),
instanceTypesList: jspb.Message.toObjectList(msg.getInstanceTypesList(),
    proto.ld.services.hosted.v1alpha1.InstanceType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse;
  return proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (reader.readEnum());
      msg.setCloud(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    case 3:
      var value = new proto.ld.services.hosted.v1alpha1.InstanceType;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.InstanceType.deserializeBinaryFromReader);
      msg.addInstanceTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloud();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstanceTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ld.services.hosted.v1alpha1.InstanceType.serializeBinaryToWriter
    );
  }
};


/**
 * optional CloudProvider cloud = 1;
 * @return {!proto.ld.services.hosted.v1alpha1.CloudProvider}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.prototype.getCloud = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.CloudProvider} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.prototype.setCloud = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string zone = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated InstanceType instance_types = 3;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.InstanceType>}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.prototype.getInstanceTypesList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.InstanceType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.InstanceType, 3));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.InstanceType>} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.prototype.setInstanceTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.InstanceType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.InstanceType}
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.prototype.addInstanceTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ld.services.hosted.v1alpha1.InstanceType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetAvailableInstanceTypesResponse.prototype.clearInstanceTypesList = function() {
  return this.setInstanceTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
cloud: jspb.Message.getFieldWithDefault(msg, 1, 0),
zone: jspb.Message.getFieldWithDefault(msg, 2, ""),
instanceTypeId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest;
  return proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (reader.readEnum());
      msg.setCloud(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloud();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstanceTypeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional CloudProvider cloud = 1;
 * @return {!proto.ld.services.hosted.v1alpha1.CloudProvider}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.prototype.getCloud = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.CloudProvider} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.prototype.setCloud = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string zone = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string instance_type_id = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.prototype.getInstanceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsRequest.prototype.setInstanceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.StorageOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.StorageOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.StorageOption.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
name: jspb.Message.getFieldWithDefault(msg, 2, ""),
description: jspb.Message.getFieldWithDefault(msg, 3, ""),
minSizeGb: jspb.Message.getFieldWithDefault(msg, 4, 0),
maxSizeGb: jspb.Message.getFieldWithDefault(msg, 5, 0),
cost: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.StorageOption}
 */
proto.ld.services.hosted.v1alpha1.StorageOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.StorageOption;
  return proto.ld.services.hosted.v1alpha1.StorageOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.StorageOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.StorageOption}
 */
proto.ld.services.hosted.v1alpha1.StorageOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinSizeGb(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxSizeGb(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.StorageOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.StorageOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.StorageOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMinSizeGb();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMaxSizeGb();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getCost();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.StorageOption} returns this
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.StorageOption} returns this
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.StorageOption} returns this
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 min_size_gb = 4;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.getMinSizeGb = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.StorageOption} returns this
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.setMinSizeGb = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 max_size_gb = 5;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.getMaxSizeGb = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.StorageOption} returns this
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.setMaxSizeGb = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 cost = 6;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.StorageOption} returns this
 */
proto.ld.services.hosted.v1alpha1.StorageOption.prototype.setCost = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
cloud: jspb.Message.getFieldWithDefault(msg, 1, 0),
zone: jspb.Message.getFieldWithDefault(msg, 2, ""),
instanceTypeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
storageOptionsList: jspb.Message.toObjectList(msg.getStorageOptionsList(),
    proto.ld.services.hosted.v1alpha1.StorageOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse;
  return proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (reader.readEnum());
      msg.setCloud(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceTypeId(value);
      break;
    case 4:
      var value = new proto.ld.services.hosted.v1alpha1.StorageOption;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.StorageOption.deserializeBinaryFromReader);
      msg.addStorageOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloud();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstanceTypeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStorageOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ld.services.hosted.v1alpha1.StorageOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional CloudProvider cloud = 1;
 * @return {!proto.ld.services.hosted.v1alpha1.CloudProvider}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.prototype.getCloud = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.CloudProvider} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.prototype.setCloud = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string zone = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string instance_type_id = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.prototype.getInstanceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.prototype.setInstanceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated StorageOption storage_options = 4;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.StorageOption>}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.prototype.getStorageOptionsList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.StorageOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.StorageOption, 4));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.StorageOption>} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.prototype.setStorageOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.StorageOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.StorageOption}
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.prototype.addStorageOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ld.services.hosted.v1alpha1.StorageOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetStorageOptionsResponse.prototype.clearStorageOptionsList = function() {
  return this.setStorageOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CalcCostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CalcCostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
cloud: jspb.Message.getFieldWithDefault(msg, 1, 0),
zone: jspb.Message.getFieldWithDefault(msg, 2, ""),
instanceTypeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
volumeTypeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
volumeSize: jspb.Message.getFieldWithDefault(msg, 6, 0),
replicas: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CalcCostRequest}
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CalcCostRequest;
  return proto.ld.services.hosted.v1alpha1.CalcCostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CalcCostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CalcCostRequest}
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (reader.readEnum());
      msg.setCloud(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceTypeId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolumeTypeId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolumeSize(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReplicas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CalcCostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CalcCostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloud();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstanceTypeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVolumeTypeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVolumeSize();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getReplicas();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional CloudProvider cloud = 1;
 * @return {!proto.ld.services.hosted.v1alpha1.CloudProvider}
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.getCloud = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.CloudProvider} value
 * @return {!proto.ld.services.hosted.v1alpha1.CalcCostRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.setCloud = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string zone = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CalcCostRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string instance_type_id = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.getInstanceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CalcCostRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.setInstanceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string volume_type_id = 5;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.getVolumeTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CalcCostRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.setVolumeTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 volume_size = 6;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.getVolumeSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.CalcCostRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.setVolumeSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 replicas = 7;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.getReplicas = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.CalcCostRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CalcCostRequest.prototype.setReplicas = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.CalcCostResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CalcCostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CalcCostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CalcCostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CalcCostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
costList: jspb.Message.toObjectList(msg.getCostList(),
    proto.ld.services.hosted.v1alpha1.Cost.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CalcCostResponse}
 */
proto.ld.services.hosted.v1alpha1.CalcCostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CalcCostResponse;
  return proto.ld.services.hosted.v1alpha1.CalcCostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CalcCostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CalcCostResponse}
 */
proto.ld.services.hosted.v1alpha1.CalcCostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.Cost;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.Cost.deserializeBinaryFromReader);
      msg.addCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CalcCostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CalcCostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CalcCostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CalcCostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.Cost.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Cost cost = 1;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.Cost>}
 */
proto.ld.services.hosted.v1alpha1.CalcCostResponse.prototype.getCostList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.Cost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.Cost, 1));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.Cost>} value
 * @return {!proto.ld.services.hosted.v1alpha1.CalcCostResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.CalcCostResponse.prototype.setCostList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.Cost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.Cost}
 */
proto.ld.services.hosted.v1alpha1.CalcCostResponse.prototype.addCost = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hosted.v1alpha1.Cost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.CalcCostResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.CalcCostResponse.prototype.clearCostList = function() {
  return this.setCostList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
cloud: jspb.Message.getFieldWithDefault(msg, 1, 0),
zone: jspb.Message.getFieldWithDefault(msg, 2, ""),
instanceTypeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
volumeTypeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
volumeSize: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest;
  return proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (reader.readEnum());
      msg.setCloud(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceTypeId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolumeTypeId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolumeSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloud();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstanceTypeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVolumeTypeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVolumeSize();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional CloudProvider cloud = 1;
 * @return {!proto.ld.services.hosted.v1alpha1.CloudProvider}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.prototype.getCloud = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.CloudProvider} value
 * @return {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.prototype.setCloud = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string zone = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string instance_type_id = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.prototype.getInstanceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.prototype.setInstanceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string volume_type_id = 5;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.prototype.getVolumeTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.prototype.setVolumeTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 volume_size = 6;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.prototype.getVolumeSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostRequest.prototype.setVolumeSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
hourlyCostUsd: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse;
  return proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHourlyCostUsd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHourlyCostUsd();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float hourly_cost_usd = 1;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse.prototype.getHourlyCostUsd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.CalcHourlyCostResponse.prototype.setHourlyCostUsd = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.Deployment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.Deployment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.Deployment.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
cloud: jspb.Message.getFieldWithDefault(msg, 2, 0),
zone: jspb.Message.getFieldWithDefault(msg, 3, ""),
instanceTypeName: jspb.Message.getFieldWithDefault(msg, 4, ""),
volumeTypeName: jspb.Message.getFieldWithDefault(msg, 5, ""),
volumeSize: jspb.Message.getFieldWithDefault(msg, 6, 0),
state: jspb.Message.getFieldWithDefault(msg, 7, 0),
host: jspb.Message.getFieldWithDefault(msg, 8, ""),
port: jspb.Message.getFieldWithDefault(msg, 9, 0),
username: jspb.Message.getFieldWithDefault(msg, 10, ""),
password: jspb.Message.getFieldWithDefault(msg, 11, ""),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
destroyAt: (f = msg.getDestroyAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
callerRole: jspb.Message.getFieldWithDefault(msg, 14, 0),
webpkiCert: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
hourlyCostUsd: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
doltVersion: jspb.Message.getFieldWithDefault(msg, 17, ""),
enableDbWrites: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
serverCertCa: jspb.Message.getFieldWithDefault(msg, 19, ""),
updateNow: (f = msg.getUpdateNow()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
exposeRemotesapiEndpoint: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
workbenchHost: jspb.Message.getFieldWithDefault(msg, 22, ""),
replicas: jspb.Message.getFieldWithDefault(msg, 23, 0),
createdBy: jspb.Message.getFieldWithDefault(msg, 24, ""),
destroyedBy: jspb.Message.getFieldWithDefault(msg, 25, ""),
disableAutomaticDoltUpdates: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
application: jspb.Message.getFieldWithDefault(msg, 27, 0),
privateLinkConfig: (f = msg.getPrivateLinkConfig()) && proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.toObject(includeInstance, f),
clusterType: jspb.Message.getFieldWithDefault(msg, 29, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment}
 */
proto.ld.services.hosted.v1alpha1.Deployment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.Deployment;
  return proto.ld.services.hosted.v1alpha1.Deployment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.Deployment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment}
 */
proto.ld.services.hosted.v1alpha1.Deployment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (reader.readEnum());
      msg.setCloud(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceTypeName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolumeTypeName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolumeSize(value);
      break;
    case 7:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.DeploymentState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPort(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDestroyAt(value);
      break;
    case 14:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.DeploymentRole} */ (reader.readEnum());
      msg.setCallerRole(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWebpkiCert(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHourlyCostUsd(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoltVersion(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableDbWrites(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setServerCertCa(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateNow(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExposeRemotesapiEndpoint(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkbenchHost(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReplicas(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestroyedBy(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableAutomaticDoltUpdates(value);
      break;
    case 27:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.Application} */ (reader.readEnum());
      msg.setApplication(value);
      break;
    case 28:
      var value = new proto.ld.services.hosted.v1alpha1.PrivateLinkConfig;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.deserializeBinaryFromReader);
      msg.setPrivateLinkConfig(value);
      break;
    case 29:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.ClusterType} */ (reader.readEnum());
      msg.setClusterType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.Deployment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.Deployment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.Deployment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCloud();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInstanceTypeName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVolumeTypeName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVolumeSize();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDestroyAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCallerRole();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getWebpkiCert();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getHourlyCostUsd();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getDoltVersion();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getEnableDbWrites();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getServerCertCa();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getUpdateNow();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExposeRemotesapiEndpoint();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getWorkbenchHost();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getReplicas();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getDestroyedBy();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getDisableAutomaticDoltUpdates();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getApplication();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getPrivateLinkConfig();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.serializeBinaryToWriter
    );
  }
  f = message.getClusterType();
  if (f !== 0.0) {
    writer.writeEnum(
      29,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CloudProvider cloud = 2;
 * @return {!proto.ld.services.hosted.v1alpha1.CloudProvider}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getCloud = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.CloudProvider} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setCloud = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string zone = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string instance_type_name = 4;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getInstanceTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setInstanceTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string volume_type_name = 5;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getVolumeTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setVolumeTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 volume_size = 6;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getVolumeSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setVolumeSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional DeploymentState state = 7;
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentState}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getState = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.DeploymentState} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentState} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string host = 8;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 port = 9;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string username = 10;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string password = 11;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
*/
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp destroy_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getDestroyAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
*/
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setDestroyAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.clearDestroyAt = function() {
  return this.setDestroyAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.hasDestroyAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional DeploymentRole caller_role = 14;
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentRole}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getCallerRole = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.DeploymentRole} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentRole} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setCallerRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional bool webpki_cert = 15;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getWebpkiCert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setWebpkiCert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional float hourly_cost_usd = 16;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getHourlyCostUsd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setHourlyCostUsd = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional string dolt_version = 17;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getDoltVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setDoltVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool enable_db_writes = 18;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getEnableDbWrites = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setEnableDbWrites = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string server_cert_ca = 19;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getServerCertCa = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setServerCertCa = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional google.protobuf.Timestamp update_now = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getUpdateNow = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
*/
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setUpdateNow = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.clearUpdateNow = function() {
  return this.setUpdateNow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.hasUpdateNow = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional bool expose_remotesapi_endpoint = 21;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getExposeRemotesapiEndpoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setExposeRemotesapiEndpoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional string workbench_host = 22;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getWorkbenchHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setWorkbenchHost = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional int32 replicas = 23;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getReplicas = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setReplicas = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional string created_by = 24;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string destroyed_by = 25;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getDestroyedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setDestroyedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional bool disable_automatic_dolt_updates = 26;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getDisableAutomaticDoltUpdates = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setDisableAutomaticDoltUpdates = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional Application application = 27;
 * @return {!proto.ld.services.hosted.v1alpha1.Application}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getApplication = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.Application} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.Application} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setApplication = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * optional PrivateLinkConfig private_link_config = 28;
 * @return {?proto.ld.services.hosted.v1alpha1.PrivateLinkConfig}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getPrivateLinkConfig = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.PrivateLinkConfig} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.PrivateLinkConfig, 28));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.PrivateLinkConfig|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
*/
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setPrivateLinkConfig = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.clearPrivateLinkConfig = function() {
  return this.setPrivateLinkConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.hasPrivateLinkConfig = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional ClusterType cluster_type = 29;
 * @return {!proto.ld.services.hosted.v1alpha1.ClusterType}
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.getClusterType = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.ClusterType} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.ClusterType} value
 * @return {!proto.ld.services.hosted.v1alpha1.Deployment} returns this
 */
proto.ld.services.hosted.v1alpha1.Deployment.prototype.setClusterType = function(value) {
  return jspb.Message.setProto3EnumField(this, 29, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.PrivateLinkConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
usePrivateLink: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
allowedPeeringAccountIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
endpointsList: jspb.Message.toObjectList(msg.getEndpointsList(),
    proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkConfig}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.PrivateLinkConfig;
  return proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.PrivateLinkConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkConfig}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsePrivateLink(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowedPeeringAccountIds(value);
      break;
    case 3:
      var value = new proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.deserializeBinaryFromReader);
      msg.addEndpoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.PrivateLinkConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsePrivateLink();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAllowedPeeringAccountIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getEndpointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool use_private_link = 1;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.prototype.getUsePrivateLink = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkConfig} returns this
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.prototype.setUsePrivateLink = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated string allowed_peering_account_ids = 2;
 * @return {!Array<string>}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.prototype.getAllowedPeeringAccountIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkConfig} returns this
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.prototype.setAllowedPeeringAccountIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkConfig} returns this
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.prototype.addAllowedPeeringAccountIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkConfig} returns this
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.prototype.clearAllowedPeeringAccountIdsList = function() {
  return this.setAllowedPeeringAccountIdsList([]);
};


/**
 * repeated PrivateLinkEndpoint endpoints = 3;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint>}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.prototype.getEndpointsList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint, 3));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint>} value
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkConfig} returns this
*/
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.prototype.setEndpointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.prototype.addEndpoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkConfig} returns this
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkConfig.prototype.clearEndpointsList = function() {
  return this.setEndpointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.toObject = function(includeInstance, msg) {
  var f, obj = {
hostname: jspb.Message.getFieldWithDefault(msg, 1, ""),
state: jspb.Message.getFieldWithDefault(msg, 2, 0),
serviceName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint;
  return proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    case 2:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpointState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string hostname = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint} returns this
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PrivateLinkEndpointState state = 2;
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpointState}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.prototype.getState = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpointState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpointState} value
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint} returns this
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string service_name = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint} returns this
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpoint.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
includeConfig: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
includeCollaborators: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings}
 */
proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings;
  return proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings}
 */
proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeConfig(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCollaborators(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeConfig();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIncludeCollaborators();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings} returns this
 */
proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_config = 2;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.prototype.getIncludeConfig = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings} returns this
 */
proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.prototype.setIncludeConfig = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool include_collaborators = 3;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.prototype.getIncludeCollaborators = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings} returns this
 */
proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.prototype.setIncludeCollaborators = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.repeatedFields_ = [18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
cloud: jspb.Message.getFieldWithDefault(msg, 2, 0),
zone: jspb.Message.getFieldWithDefault(msg, 3, ""),
instanceTypeId: jspb.Message.getFieldWithDefault(msg, 4, ""),
volumeTypeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
volumeSize: jspb.Message.getFieldWithDefault(msg, 6, 0),
flag: jspb.Message.getFieldWithDefault(msg, 7, 0),
webpkiCert: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
canCreateWorkbenchUsers: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
backupName: jspb.Message.getFieldWithDefault(msg, 10, ""),
exposeRemotesapiEndpoint: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
existingDeploymentSettings: (f = msg.getExistingDeploymentSettings()) && proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.toObject(includeInstance, f),
exposeDoltCredentials: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
replicas: jspb.Message.getFieldWithDefault(msg, 14, 0),
application: jspb.Message.getFieldWithDefault(msg, 15, 0),
usePrivateLink: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
clusterType: jspb.Message.getFieldWithDefault(msg, 17, 0),
allowedPeeringAccountIdsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest;
  return proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (reader.readEnum());
      msg.setCloud(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceTypeId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolumeTypeId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolumeSize(value);
      break;
    case 7:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.DeploymentFlag} */ (reader.readEnum());
      msg.setFlag(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWebpkiCert(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanCreateWorkbenchUsers(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackupName(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExposeRemotesapiEndpoint(value);
      break;
    case 12:
      var value = new proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.deserializeBinaryFromReader);
      msg.setExistingDeploymentSettings(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExposeDoltCredentials(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReplicas(value);
      break;
    case 15:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.Application} */ (reader.readEnum());
      msg.setApplication(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsePrivateLink(value);
      break;
    case 17:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.ClusterType} */ (reader.readEnum());
      msg.setClusterType(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowedPeeringAccountIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCloud();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInstanceTypeId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVolumeTypeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVolumeSize();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getFlag();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getWebpkiCert();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCanCreateWorkbenchUsers();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getBackupName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getExposeRemotesapiEndpoint();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getExistingDeploymentSettings();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings.serializeBinaryToWriter
    );
  }
  f = message.getExposeDoltCredentials();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getReplicas();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getApplication();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getUsePrivateLink();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getClusterType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getAllowedPeeringAccountIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      18,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CloudProvider cloud = 2;
 * @return {!proto.ld.services.hosted.v1alpha1.CloudProvider}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getCloud = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.CloudProvider} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setCloud = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string zone = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string instance_type_id = 4;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getInstanceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setInstanceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string volume_type_id = 5;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getVolumeTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setVolumeTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 volume_size = 6;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getVolumeSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setVolumeSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional DeploymentFlag flag = 7;
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentFlag}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getFlag = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.DeploymentFlag} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentFlag} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setFlag = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool webpki_cert = 8;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getWebpkiCert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setWebpkiCert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool can_create_workbench_users = 9;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getCanCreateWorkbenchUsers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setCanCreateWorkbenchUsers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string backup_name = 10;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getBackupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setBackupName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool expose_remotesapi_endpoint = 11;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getExposeRemotesapiEndpoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setExposeRemotesapiEndpoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional ExistingDeploymentSettings existing_deployment_settings = 12;
 * @return {?proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getExistingDeploymentSettings = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings, 12));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.ExistingDeploymentSettings|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setExistingDeploymentSettings = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.clearExistingDeploymentSettings = function() {
  return this.setExistingDeploymentSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.hasExistingDeploymentSettings = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool expose_dolt_credentials = 13;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getExposeDoltCredentials = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setExposeDoltCredentials = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 replicas = 14;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getReplicas = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setReplicas = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional Application application = 15;
 * @return {!proto.ld.services.hosted.v1alpha1.Application}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getApplication = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.Application} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.Application} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setApplication = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional bool use_private_link = 16;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getUsePrivateLink = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setUsePrivateLink = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional ClusterType cluster_type = 17;
 * @return {!proto.ld.services.hosted.v1alpha1.ClusterType}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getClusterType = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.ClusterType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.ClusterType} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setClusterType = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * repeated string allowed_peering_account_ids = 18;
 * @return {!Array<string>}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.getAllowedPeeringAccountIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.setAllowedPeeringAccountIdsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.addAllowedPeeringAccountIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentRequest.prototype.clearAllowedPeeringAccountIdsList = function() {
  return this.setAllowedPeeringAccountIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse;
  return proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentResponse.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest}
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest;
  return proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest}
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse}
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse;
  return proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse}
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DestroyDeploymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
ownerName: jspb.Message.getFieldWithDefault(msg, 1, ""),
stateFilter: jspb.Message.getFieldWithDefault(msg, 2, 0),
pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest;
  return proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerName(value);
      break;
    case 2:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.DeploymentState} */ (reader.readEnum());
      msg.setStateFilter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStateFilter();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string owner_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.prototype.getOwnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.prototype.setOwnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeploymentState state_filter = 2;
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentState}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.prototype.getStateFilter = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.DeploymentState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentState} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.prototype.setStateFilter = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
cloud: jspb.Message.getFieldWithDefault(msg, 2, 0),
zone: jspb.Message.getFieldWithDefault(msg, 3, ""),
instanceTypeName: jspb.Message.getFieldWithDefault(msg, 4, ""),
volumeTypeName: jspb.Message.getFieldWithDefault(msg, 5, ""),
volumeSize: jspb.Message.getFieldWithDefault(msg, 6, 0),
state: jspb.Message.getFieldWithDefault(msg, 7, 0),
webpkiCert: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
hourlyCostUsd: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
doltVersion: jspb.Message.getFieldWithDefault(msg, 10, ""),
replicas: jspb.Message.getFieldWithDefault(msg, 11, 0),
lastBackupSize: jspb.Message.getFieldWithDefault(msg, 12, 0),
lastBackupTime: (f = msg.getLastBackupTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
application: jspb.Message.getFieldWithDefault(msg, 14, 0),
clusterType: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListDeploymentsItem;
  return proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 2:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (reader.readEnum());
      msg.setCloud(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setZone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceTypeName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolumeTypeName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolumeSize(value);
      break;
    case 7:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.DeploymentState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWebpkiCert(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHourlyCostUsd(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoltVersion(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReplicas(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastBackupSize(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastBackupTime(value);
      break;
    case 14:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.Application} */ (reader.readEnum());
      msg.setApplication(value);
      break;
    case 15:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.ClusterType} */ (reader.readEnum());
      msg.setClusterType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCloud();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getZone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInstanceTypeName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVolumeTypeName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVolumeSize();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getWebpkiCert();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getHourlyCostUsd();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getDoltVersion();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReplicas();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getLastBackupSize();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getLastBackupTime();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getApplication();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getClusterType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CloudProvider cloud = 2;
 * @return {!proto.ld.services.hosted.v1alpha1.CloudProvider}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getCloud = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.CloudProvider} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.CloudProvider} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setCloud = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string zone = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setZone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string instance_type_name = 4;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getInstanceTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setInstanceTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string volume_type_name = 5;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getVolumeTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setVolumeTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 volume_size = 6;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getVolumeSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setVolumeSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional DeploymentState state = 7;
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentState}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getState = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.DeploymentState} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentState} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool webpki_cert = 8;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getWebpkiCert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setWebpkiCert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional float hourly_cost_usd = 9;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getHourlyCostUsd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setHourlyCostUsd = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string dolt_version = 10;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getDoltVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setDoltVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 replicas = 11;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getReplicas = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setReplicas = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 last_backup_size = 12;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getLastBackupSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setLastBackupSize = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional google.protobuf.Timestamp last_backup_time = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getLastBackupTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
*/
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setLastBackupTime = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.clearLastBackupTime = function() {
  return this.setLastBackupTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.hasLastBackupTime = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Application application = 14;
 * @return {!proto.ld.services.hosted.v1alpha1.Application}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getApplication = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.Application} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.Application} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setApplication = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional ClusterType cluster_type = 15;
 * @return {!proto.ld.services.hosted.v1alpha1.ClusterType}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.getClusterType = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.ClusterType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.ClusterType} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.prototype.setClusterType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentsList: jspb.Message.toObjectList(msg.getDeploymentsList(),
    proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.toObject, includeInstance),
nextPage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse;
  return proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.ListDeploymentsItem;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.deserializeBinaryFromReader);
      msg.addDeployments(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.serializeBinaryToWriter
    );
  }
  f = message.getNextPage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated ListDeploymentsItem deployments = 1;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem>}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.prototype.getDeploymentsList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.ListDeploymentsItem, 1));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem>} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.prototype.setDeploymentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.prototype.addDeployments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hosted.v1alpha1.ListDeploymentsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.prototype.clearDeploymentsList = function() {
  return this.setDeploymentsList([]);
};


/**
 * optional string next_page = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.prototype.getNextPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsResponse.prototype.setNextPage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
stateFilter: jspb.Message.getFieldWithDefault(msg, 2, 0),
ownerFilter: jspb.Message.getFieldWithDefault(msg, 3, ""),
pageToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
query: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest;
  return proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 2:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.DeploymentState} */ (reader.readEnum());
      msg.setStateFilter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerFilter(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStateFilter();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOwnerFilter();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeploymentState state_filter = 2;
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentState}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.prototype.getStateFilter = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.DeploymentState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentState} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.prototype.setStateFilter = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string owner_filter = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.prototype.getOwnerFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.prototype.setOwnerFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string page_token = 4;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string query = 5;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentsList: jspb.Message.toObjectList(msg.getDeploymentsList(),
    proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.toObject, includeInstance),
nextPage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse;
  return proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.ListDeploymentsItem;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.deserializeBinaryFromReader);
      msg.addDeployments(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.ListDeploymentsItem.serializeBinaryToWriter
    );
  }
  f = message.getNextPage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated ListDeploymentsItem deployments = 1;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem>}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.prototype.getDeploymentsList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.ListDeploymentsItem, 1));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem>} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.prototype.setDeploymentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsItem}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.prototype.addDeployments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hosted.v1alpha1.ListDeploymentsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.prototype.clearDeploymentsList = function() {
  return this.setDeploymentsList([]);
};


/**
 * optional string next_page = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.prototype.getNextPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListUserDeploymentsResponse.prototype.setNextPage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetDeploymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetDeploymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentRequest}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetDeploymentRequest;
  return proto.ld.services.hosted.v1alpha1.GetDeploymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetDeploymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentRequest}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetDeploymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetDeploymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetDeploymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
deployment: (f = msg.getDeployment()) && proto.ld.services.hosted.v1alpha1.Deployment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentResponse}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetDeploymentResponse;
  return proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetDeploymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentResponse}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.Deployment;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.Deployment.deserializeBinaryFromReader);
      msg.setDeployment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetDeploymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeployment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.Deployment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Deployment deployment = 1;
 * @return {?proto.ld.services.hosted.v1alpha1.Deployment}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.prototype.getDeployment = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.Deployment} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.Deployment, 1));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.Deployment|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.prototype.setDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.prototype.clearDeployment = function() {
  return this.setDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentResponse.prototype.hasDeployment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deployment: (f = msg.getDeployment()) && proto.ld.services.hosted.v1alpha1.Deployment.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest;
  return proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.Deployment;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.Deployment.deserializeBinaryFromReader);
      msg.setDeployment(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeployment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.Deployment.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional Deployment deployment = 1;
 * @return {?proto.ld.services.hosted.v1alpha1.Deployment}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.prototype.getDeployment = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.Deployment} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.Deployment, 1));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.Deployment|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.prototype.setDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.prototype.clearDeployment = function() {
  return this.setDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.prototype.hasDeployment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.DeploymentInstance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentInstance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
host: jspb.Message.getFieldWithDefault(msg, 2, ""),
index: jspb.Message.getFieldWithDefault(msg, 3, 0),
isPrimary: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
instanceTypeName: jspb.Message.getFieldWithDefault(msg, 5, ""),
volumeTypeName: jspb.Message.getFieldWithDefault(msg, 6, ""),
volumeSize: jspb.Message.getFieldWithDefault(msg, 7, 0),
hourlyCost: (f = msg.getHourlyCost()) && google_type_money_pb.Money.toObject(includeInstance, f),
application: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentInstance}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.DeploymentInstance;
  return proto.ld.services.hosted.v1alpha1.DeploymentInstance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentInstance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentInstance}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPrimary(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceTypeName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolumeTypeName(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolumeSize(value);
      break;
    case 8:
      var value = new google_type_money_pb.Money;
      reader.readMessage(value,google_type_money_pb.Money.deserializeBinaryFromReader);
      msg.setHourlyCost(value);
      break;
    case 9:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.Application} */ (reader.readEnum());
      msg.setApplication(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.DeploymentInstance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentInstance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIsPrimary();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getInstanceTypeName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVolumeTypeName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVolumeSize();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getHourlyCost();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getApplication();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentInstance} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string host = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentInstance} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 index = 3;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentInstance} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_primary = 4;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.getIsPrimary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentInstance} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.setIsPrimary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string instance_type_name = 5;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.getInstanceTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentInstance} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.setInstanceTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string volume_type_name = 6;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.getVolumeTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentInstance} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.setVolumeTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 volume_size = 7;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.getVolumeSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentInstance} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.setVolumeSize = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.type.Money hourly_cost = 8;
 * @return {?proto.google.type.Money}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.getHourlyCost = function() {
  return /** @type{?proto.google.type.Money} */ (
    jspb.Message.getWrapperField(this, google_type_money_pb.Money, 8));
};


/**
 * @param {?proto.google.type.Money|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentInstance} returns this
*/
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.setHourlyCost = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentInstance} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.clearHourlyCost = function() {
  return this.setHourlyCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.hasHourlyCost = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Application application = 9;
 * @return {!proto.ld.services.hosted.v1alpha1.Application}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.getApplication = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.Application} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.Application} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentInstance} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstance.prototype.setApplication = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest;
  return proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
instancesList: jspb.Message.toObjectList(msg.getInstancesList(),
    proto.ld.services.hosted.v1alpha1.DeploymentInstance.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse;
  return proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.DeploymentInstance;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.DeploymentInstance.deserializeBinaryFromReader);
      msg.addInstances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.DeploymentInstance.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeploymentInstance instances = 1;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.DeploymentInstance>}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.prototype.getInstancesList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.DeploymentInstance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.DeploymentInstance, 1));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.DeploymentInstance>} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.prototype.setInstancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentInstance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentInstance}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.prototype.addInstances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hosted.v1alpha1.DeploymentInstance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentInstancesResponse.prototype.clearInstancesList = function() {
  return this.setInstancesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
instanceId: jspb.Message.getFieldWithDefault(msg, 8, ""),
numLines: jspb.Message.getFieldWithDefault(msg, 3, 0),
startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
nextToken: jspb.Message.getFieldWithDefault(msg, 6, ""),
prevToken: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetLogsRequest;
  return proto.ld.services.hosted.v1alpha1.GetLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumLines(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextToken(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstanceId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNumLines();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNextToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPrevToken();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string instance_id = 8;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.getInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.setInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 num_lines = 3;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.getNumLines = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.setNumLines = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string next_token = 6;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.getNextToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.setNextToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string prev_token = 7;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.getPrevToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetLogsRequest.prototype.setPrevToken = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.LogLine.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.LogLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.LogLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.LogLine.toObject = function(includeInstance, msg) {
  var f, obj = {
time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.LogLine}
 */
proto.ld.services.hosted.v1alpha1.LogLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.LogLine;
  return proto.ld.services.hosted.v1alpha1.LogLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.LogLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.LogLine}
 */
proto.ld.services.hosted.v1alpha1.LogLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.LogLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.LogLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.LogLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.LogLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.LogLine.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.LogLine} returns this
*/
proto.ld.services.hosted.v1alpha1.LogLine.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.LogLine} returns this
 */
proto.ld.services.hosted.v1alpha1.LogLine.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.LogLine.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.LogLine.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.LogLine} returns this
 */
proto.ld.services.hosted.v1alpha1.LogLine.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetLogsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetLogsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
logsList: jspb.Message.toObjectList(msg.getLogsList(),
    proto.ld.services.hosted.v1alpha1.LogLine.toObject, includeInstance),
nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
prevPageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsResponse}
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetLogsResponse;
  return proto.ld.services.hosted.v1alpha1.GetLogsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetLogsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsResponse}
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.LogLine;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.LogLine.deserializeBinaryFromReader);
      msg.addLogs(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetLogsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetLogsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.LogLine.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrevPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated LogLine logs = 1;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.LogLine>}
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.prototype.getLogsList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.LogLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.LogLine, 1));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.LogLine>} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetLogsResponse.prototype.setLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.LogLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.LogLine}
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.prototype.addLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hosted.v1alpha1.LogLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.prototype.clearLogsList = function() {
  return this.setLogsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string prev_page_token = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.prototype.getPrevPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetLogsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetLogsResponse.prototype.setPrevPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetDashboardsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetDashboardsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDashboardsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetDashboardsRequest;
  return proto.ld.services.hosted.v1alpha1.GetDashboardsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetDashboardsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDashboardsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetDashboardsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetDashboardsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetDashboardsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.Dashboard.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.Dashboard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.Dashboard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.Dashboard.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.Dashboard}
 */
proto.ld.services.hosted.v1alpha1.Dashboard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.Dashboard;
  return proto.ld.services.hosted.v1alpha1.Dashboard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.Dashboard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.Dashboard}
 */
proto.ld.services.hosted.v1alpha1.Dashboard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.Dashboard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.Dashboard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.Dashboard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.Dashboard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Dashboard.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Dashboard} returns this
 */
proto.ld.services.hosted.v1alpha1.Dashboard.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Dashboard.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Dashboard} returns this
 */
proto.ld.services.hosted.v1alpha1.Dashboard.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetDashboardsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
dashboardsList: jspb.Message.toObjectList(msg.getDashboardsList(),
    proto.ld.services.hosted.v1alpha1.Dashboard.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDashboardsResponse}
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetDashboardsResponse;
  return proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetDashboardsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDashboardsResponse}
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.Dashboard;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.Dashboard.deserializeBinaryFromReader);
      msg.addDashboards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetDashboardsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.Dashboard.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Dashboard dashboards = 1;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.Dashboard>}
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.prototype.getDashboardsList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.Dashboard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.Dashboard, 1));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.Dashboard>} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetDashboardsResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.prototype.setDashboardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.Dashboard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.Dashboard}
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.prototype.addDashboards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hosted.v1alpha1.Dashboard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDashboardsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetDashboardsResponse.prototype.clearDashboardsList = function() {
  return this.setDashboardsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetGraphsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetGraphsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
dashboardId: jspb.Message.getFieldWithDefault(msg, 1, ""),
startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetGraphsRequest;
  return proto.ld.services.hosted.v1alpha1.GetGraphsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetGraphsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDashboardId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetGraphsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetGraphsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string dashboard_id = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.prototype.getDashboardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.prototype.setDashboardId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphsRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphsRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsRequest.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.Graph.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.Graph.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.Graph} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.Graph.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.Graph}
 */
proto.ld.services.hosted.v1alpha1.Graph.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.Graph;
  return proto.ld.services.hosted.v1alpha1.Graph.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.Graph} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.Graph}
 */
proto.ld.services.hosted.v1alpha1.Graph.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.Graph.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.Graph.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.Graph} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.Graph.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Graph.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Graph} returns this
 */
proto.ld.services.hosted.v1alpha1.Graph.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Graph.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Graph} returns this
 */
proto.ld.services.hosted.v1alpha1.Graph.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.GetGraphsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetGraphsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetGraphsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetGraphsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
graphsList: jspb.Message.toObjectList(msg.getGraphsList(),
    proto.ld.services.hosted.v1alpha1.Graph.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphsResponse}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetGraphsResponse;
  return proto.ld.services.hosted.v1alpha1.GetGraphsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetGraphsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphsResponse}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.Graph;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.Graph.deserializeBinaryFromReader);
      msg.addGraphs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetGraphsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetGraphsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetGraphsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGraphsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.Graph.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Graph graphs = 1;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.Graph>}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsResponse.prototype.getGraphsList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.Graph, 1));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.Graph>} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphsResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetGraphsResponse.prototype.setGraphsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.Graph}
 */
proto.ld.services.hosted.v1alpha1.GetGraphsResponse.prototype.addGraphs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hosted.v1alpha1.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphsResponse.prototype.clearGraphsList = function() {
  return this.setGraphsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetGraphDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
graphId: jspb.Message.getFieldWithDefault(msg, 1, ""),
startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
instanceId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataRequest}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetGraphDataRequest;
  return proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetGraphDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataRequest}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGraphId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetGraphDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGraphId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInstanceId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string graph_id = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.getGraphId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.setGraphId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string instance_id = 4;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.getInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataRequest.prototype.setInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.GraphData.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GraphData.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GraphData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GraphData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GraphData.toObject = function(includeInstance, msg) {
  var f, obj = {
metricName: jspb.Message.getFieldWithDefault(msg, 1, ""),
useY0: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
valuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GraphData}
 */
proto.ld.services.hosted.v1alpha1.GraphData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GraphData;
  return proto.ld.services.hosted.v1alpha1.GraphData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GraphData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GraphData}
 */
proto.ld.services.hosted.v1alpha1.GraphData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetricName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseY0(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addValues(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GraphData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GraphData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GraphData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GraphData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetricName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUseY0();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writePackedFloat(
      3,
      f
    );
  }
};


/**
 * optional string metric_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GraphData.prototype.getMetricName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GraphData} returns this
 */
proto.ld.services.hosted.v1alpha1.GraphData.prototype.setMetricName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool use_y0 = 2;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GraphData.prototype.getUseY0 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.GraphData} returns this
 */
proto.ld.services.hosted.v1alpha1.GraphData.prototype.setUseY0 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated float values = 3;
 * @return {!Array<number>}
 */
proto.ld.services.hosted.v1alpha1.GraphData.prototype.getValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.ld.services.hosted.v1alpha1.GraphData} returns this
 */
proto.ld.services.hosted.v1alpha1.GraphData.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.GraphData} returns this
 */
proto.ld.services.hosted.v1alpha1.GraphData.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.GraphData} returns this
 */
proto.ld.services.hosted.v1alpha1.GraphData.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.repeatedFields_ = [3,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
valueTimestampsList: jspb.Message.toObjectList(msg.getValueTimestampsList(),
    google_protobuf_timestamp_pb.Timestamp.toObject, includeInstance),
xAxis: jspb.Message.getFieldWithDefault(msg, 4, ""),
y0Axis: jspb.Message.getFieldWithDefault(msg, 5, ""),
y1Axis: jspb.Message.getFieldWithDefault(msg, 6, ""),
graphsList: jspb.Message.toObjectList(msg.getGraphsList(),
    proto.ld.services.hosted.v1alpha1.GraphData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetGraphDataResponse;
  return proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.addValueTimestamps(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setXAxis(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setY0Axis(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setY1Axis(value);
      break;
    case 7:
      var value = new proto.ld.services.hosted.v1alpha1.GraphData;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.GraphData.deserializeBinaryFromReader);
      msg.addGraphs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValueTimestampsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getXAxis();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getY0Axis();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getY1Axis();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGraphsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.ld.services.hosted.v1alpha1.GraphData.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp start_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated google.protobuf.Timestamp value_timestamps = 3;
 * @return {!Array<!proto.google.protobuf.Timestamp>}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.getValueTimestampsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Timestamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {!Array<!proto.google.protobuf.Timestamp>} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.setValueTimestampsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.protobuf.Timestamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.addValueTimestamps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.protobuf.Timestamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.clearValueTimestampsList = function() {
  return this.setValueTimestampsList([]);
};


/**
 * optional string x_axis = 4;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.getXAxis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.setXAxis = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string y0_axis = 5;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.getY0Axis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.setY0Axis = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string y1_axis = 6;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.getY1Axis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.setY1Axis = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated GraphData graphs = 7;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.GraphData>}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.getGraphsList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.GraphData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.GraphData, 7));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.GraphData>} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.setGraphsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.GraphData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.GraphData}
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.addGraphs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.ld.services.hosted.v1alpha1.GraphData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.GetGraphDataResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetGraphDataResponse.prototype.clearGraphsList = function() {
  return this.setGraphsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
emailAddress: jspb.Message.getFieldWithDefault(msg, 4, ""),
permissionLevel: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator}
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.DeploymentCollaborator;
  return proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator}
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 3:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.CollaboratorPermissionLevel} */ (reader.readEnum());
      msg.setPermissionLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPermissionLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email_address = 4;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional CollaboratorPermissionLevel permission_level = 3;
 * @return {!proto.ld.services.hosted.v1alpha1.CollaboratorPermissionLevel}
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.prototype.getPermissionLevel = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.CollaboratorPermissionLevel} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.CollaboratorPermissionLevel} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.prototype.setPermissionLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
collaborator: (f = msg.getCollaborator()) && proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest;
  return proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 2:
      var value = new proto.ld.services.hosted.v1alpha1.DeploymentCollaborator;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.deserializeBinaryFromReader);
      msg.setCollaborator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCollaborator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeploymentCollaborator collaborator = 2;
 * @return {?proto.ld.services.hosted.v1alpha1.DeploymentCollaborator}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.prototype.getCollaborator = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.DeploymentCollaborator} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.DeploymentCollaborator, 2));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.DeploymentCollaborator|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.prototype.setCollaborator = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.prototype.clearCollaborator = function() {
  return this.setCollaborator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentCollaboratorRequest.prototype.hasCollaborator = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest;
  return proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
collaboratorsList: jspb.Message.toObjectList(msg.getCollaboratorsList(),
    proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.toObject, includeInstance),
nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse;
  return proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.DeploymentCollaborator;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.deserializeBinaryFromReader);
      msg.addCollaborators(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCollaboratorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated DeploymentCollaborator collaborators = 1;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator>}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.prototype.getCollaboratorsList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.DeploymentCollaborator, 1));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator>} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.prototype.setCollaboratorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentCollaborator}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.prototype.addCollaborators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hosted.v1alpha1.DeploymentCollaborator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.prototype.clearCollaboratorsList = function() {
  return this.setCollaboratorsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentCollaboratorsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
collaborator: (f = msg.getCollaborator()) && proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest;
  return proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.DeploymentCollaborator;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.deserializeBinaryFromReader);
      msg.setCollaborator(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCollaborator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.DeploymentCollaborator.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeploymentCollaborator collaborator = 1;
 * @return {?proto.ld.services.hosted.v1alpha1.DeploymentCollaborator}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.prototype.getCollaborator = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.DeploymentCollaborator} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.DeploymentCollaborator, 1));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.DeploymentCollaborator|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.prototype.setCollaborator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.prototype.clearCollaborator = function() {
  return this.setCollaborator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.prototype.hasCollaborator = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentCollaboratorRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
email: jspb.Message.getFieldWithDefault(msg, 2, ""),
deploymentName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest;
  return proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deployment_name = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentCollaboratorRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest;
  return proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
username: jspb.Message.getFieldWithDefault(msg, 1, ""),
jwt: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse}
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse;
  return proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse}
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getJwt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string jwt = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.prototype.getJwt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDatabaseCredentialsResponse.prototype.setJwt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
ownerName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest;
  return proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string owner_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest.prototype.getOwnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentsWithBackupsRequest.prototype.setOwnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse;
  return proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeploymentNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string deployment_names = 1;
 * @return {!Array<string>}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.prototype.getDeploymentNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.prototype.setDeploymentNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.prototype.addDeploymentNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentWithBackupsResponse.prototype.clearDeploymentNamesList = function() {
  return this.setDeploymentNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.IntOverrideValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.IntOverrideValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.IntOverrideValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.IntOverrideValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
minValue: jspb.Message.getFieldWithDefault(msg, 1, 0),
maxValue: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.IntOverrideValidation}
 */
proto.ld.services.hosted.v1alpha1.IntOverrideValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.IntOverrideValidation;
  return proto.ld.services.hosted.v1alpha1.IntOverrideValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.IntOverrideValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.IntOverrideValidation}
 */
proto.ld.services.hosted.v1alpha1.IntOverrideValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.IntOverrideValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.IntOverrideValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.IntOverrideValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.IntOverrideValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinValue();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMaxValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 min_value = 1;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.IntOverrideValidation.prototype.getMinValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.IntOverrideValidation} returns this
 */
proto.ld.services.hosted.v1alpha1.IntOverrideValidation.prototype.setMinValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 max_value = 2;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.IntOverrideValidation.prototype.getMaxValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.IntOverrideValidation} returns this
 */
proto.ld.services.hosted.v1alpha1.IntOverrideValidation.prototype.setMaxValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.StringOverrideValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.StringOverrideValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.StringOverrideValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.StringOverrideValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
validationRegex: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.StringOverrideValidation}
 */
proto.ld.services.hosted.v1alpha1.StringOverrideValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.StringOverrideValidation;
  return proto.ld.services.hosted.v1alpha1.StringOverrideValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.StringOverrideValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.StringOverrideValidation}
 */
proto.ld.services.hosted.v1alpha1.StringOverrideValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationRegex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.StringOverrideValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.StringOverrideValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.StringOverrideValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.StringOverrideValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValidationRegex();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string validation_regex = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.StringOverrideValidation.prototype.getValidationRegex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.StringOverrideValidation} returns this
 */
proto.ld.services.hosted.v1alpha1.StringOverrideValidation.prototype.setValidationRegex = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.BoolOverrideValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.BoolOverrideValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.BoolOverrideValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.BoolOverrideValidation.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.BoolOverrideValidation}
 */
proto.ld.services.hosted.v1alpha1.BoolOverrideValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.BoolOverrideValidation;
  return proto.ld.services.hosted.v1alpha1.BoolOverrideValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.BoolOverrideValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.BoolOverrideValidation}
 */
proto.ld.services.hosted.v1alpha1.BoolOverrideValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.BoolOverrideValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.BoolOverrideValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.BoolOverrideValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.BoolOverrideValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.EnumOverrideValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
validValuesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.EnumOverrideValidation}
 */
proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.EnumOverrideValidation;
  return proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.EnumOverrideValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.EnumOverrideValidation}
 */
proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addValidValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.EnumOverrideValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValidValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * repeated string valid_values = 3;
 * @return {!Array<string>}
 */
proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.prototype.getValidValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ld.services.hosted.v1alpha1.EnumOverrideValidation} returns this
 */
proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.prototype.setValidValuesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.EnumOverrideValidation} returns this
 */
proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.prototype.addValidValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.EnumOverrideValidation} returns this
 */
proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.prototype.clearValidValuesList = function() {
  return this.setValidValuesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.Override.oneofGroups_ = [[3,4,5,6]];

/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.Override.ValidationCase = {
  VALIDATION_NOT_SET: 0,
  INT_VALIDATION: 3,
  STR_VALIDATION: 4,
  BOOL_VALIDATION: 5,
  ENUM_VALIDATION: 6
};

/**
 * @return {proto.ld.services.hosted.v1alpha1.Override.ValidationCase}
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.getValidationCase = function() {
  return /** @type {proto.ld.services.hosted.v1alpha1.Override.ValidationCase} */(jspb.Message.computeOneofCase(this, proto.ld.services.hosted.v1alpha1.Override.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.Override.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.Override} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.Override.toObject = function(includeInstance, msg) {
  var f, obj = {
key: jspb.Message.getFieldWithDefault(msg, 1, ""),
pb_default: jspb.Message.getFieldWithDefault(msg, 2, ""),
intValidation: (f = msg.getIntValidation()) && proto.ld.services.hosted.v1alpha1.IntOverrideValidation.toObject(includeInstance, f),
strValidation: (f = msg.getStrValidation()) && proto.ld.services.hosted.v1alpha1.StringOverrideValidation.toObject(includeInstance, f),
boolValidation: (f = msg.getBoolValidation()) && proto.ld.services.hosted.v1alpha1.BoolOverrideValidation.toObject(includeInstance, f),
enumValidation: (f = msg.getEnumValidation()) && proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.Override}
 */
proto.ld.services.hosted.v1alpha1.Override.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.Override;
  return proto.ld.services.hosted.v1alpha1.Override.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.Override} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.Override}
 */
proto.ld.services.hosted.v1alpha1.Override.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefault(value);
      break;
    case 3:
      var value = new proto.ld.services.hosted.v1alpha1.IntOverrideValidation;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.IntOverrideValidation.deserializeBinaryFromReader);
      msg.setIntValidation(value);
      break;
    case 4:
      var value = new proto.ld.services.hosted.v1alpha1.StringOverrideValidation;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.StringOverrideValidation.deserializeBinaryFromReader);
      msg.setStrValidation(value);
      break;
    case 5:
      var value = new proto.ld.services.hosted.v1alpha1.BoolOverrideValidation;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.BoolOverrideValidation.deserializeBinaryFromReader);
      msg.setBoolValidation(value);
      break;
    case 6:
      var value = new proto.ld.services.hosted.v1alpha1.EnumOverrideValidation;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.deserializeBinaryFromReader);
      msg.setEnumValidation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.Override.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.Override} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.Override.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDefault();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIntValidation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ld.services.hosted.v1alpha1.IntOverrideValidation.serializeBinaryToWriter
    );
  }
  f = message.getStrValidation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ld.services.hosted.v1alpha1.StringOverrideValidation.serializeBinaryToWriter
    );
  }
  f = message.getBoolValidation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ld.services.hosted.v1alpha1.BoolOverrideValidation.serializeBinaryToWriter
    );
  }
  f = message.getEnumValidation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.ld.services.hosted.v1alpha1.EnumOverrideValidation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Override} returns this
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string default = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.getDefault = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Override} returns this
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.setDefault = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional IntOverrideValidation int_validation = 3;
 * @return {?proto.ld.services.hosted.v1alpha1.IntOverrideValidation}
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.getIntValidation = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.IntOverrideValidation} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.IntOverrideValidation, 3));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.IntOverrideValidation|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.Override} returns this
*/
proto.ld.services.hosted.v1alpha1.Override.prototype.setIntValidation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.ld.services.hosted.v1alpha1.Override.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.Override} returns this
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.clearIntValidation = function() {
  return this.setIntValidation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.hasIntValidation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional StringOverrideValidation str_validation = 4;
 * @return {?proto.ld.services.hosted.v1alpha1.StringOverrideValidation}
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.getStrValidation = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.StringOverrideValidation} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.StringOverrideValidation, 4));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.StringOverrideValidation|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.Override} returns this
*/
proto.ld.services.hosted.v1alpha1.Override.prototype.setStrValidation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.ld.services.hosted.v1alpha1.Override.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.Override} returns this
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.clearStrValidation = function() {
  return this.setStrValidation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.hasStrValidation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional BoolOverrideValidation bool_validation = 5;
 * @return {?proto.ld.services.hosted.v1alpha1.BoolOverrideValidation}
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.getBoolValidation = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.BoolOverrideValidation} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.BoolOverrideValidation, 5));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.BoolOverrideValidation|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.Override} returns this
*/
proto.ld.services.hosted.v1alpha1.Override.prototype.setBoolValidation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.ld.services.hosted.v1alpha1.Override.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.Override} returns this
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.clearBoolValidation = function() {
  return this.setBoolValidation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.hasBoolValidation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional EnumOverrideValidation enum_validation = 6;
 * @return {?proto.ld.services.hosted.v1alpha1.EnumOverrideValidation}
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.getEnumValidation = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.EnumOverrideValidation} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.EnumOverrideValidation, 6));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.EnumOverrideValidation|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.Override} returns this
*/
proto.ld.services.hosted.v1alpha1.Override.prototype.setEnumValidation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.ld.services.hosted.v1alpha1.Override.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.Override} returns this
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.clearEnumValidation = function() {
  return this.setEnumValidation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.Override.prototype.hasEnumValidation = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.OverrideList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.OverrideList.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.OverrideList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.OverrideList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.OverrideList.toObject = function(includeInstance, msg) {
  var f, obj = {
overridesList: jspb.Message.toObjectList(msg.getOverridesList(),
    proto.ld.services.hosted.v1alpha1.Override.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.OverrideList}
 */
proto.ld.services.hosted.v1alpha1.OverrideList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.OverrideList;
  return proto.ld.services.hosted.v1alpha1.OverrideList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.OverrideList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.OverrideList}
 */
proto.ld.services.hosted.v1alpha1.OverrideList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.Override;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.Override.deserializeBinaryFromReader);
      msg.addOverrides(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.OverrideList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.OverrideList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.OverrideList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.OverrideList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOverridesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.Override.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Override overrides = 1;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.Override>}
 */
proto.ld.services.hosted.v1alpha1.OverrideList.prototype.getOverridesList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.Override>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.Override, 1));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.Override>} value
 * @return {!proto.ld.services.hosted.v1alpha1.OverrideList} returns this
*/
proto.ld.services.hosted.v1alpha1.OverrideList.prototype.setOverridesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.Override=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.Override}
 */
proto.ld.services.hosted.v1alpha1.OverrideList.prototype.addOverrides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hosted.v1alpha1.Override, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.OverrideList} returns this
 */
proto.ld.services.hosted.v1alpha1.OverrideList.prototype.clearOverridesList = function() {
  return this.setOverridesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest;
  return proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
supportedoverrides: (f = msg.getSupportedoverrides()) && proto.ld.services.hosted.v1alpha1.OverrideList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse;
  return proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.OverrideList;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.OverrideList.deserializeBinaryFromReader);
      msg.setSupportedoverrides(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupportedoverrides();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.OverrideList.serializeBinaryToWriter
    );
  }
};


/**
 * optional OverrideList SupportedOverrides = 1;
 * @return {?proto.ld.services.hosted.v1alpha1.OverrideList}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.prototype.getSupportedoverrides = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.OverrideList} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.OverrideList, 1));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.OverrideList|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.prototype.setSupportedoverrides = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.prototype.clearSupportedoverrides = function() {
  return this.setSupportedoverrides(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetSupportedOverridesResponse.prototype.hasSupportedoverrides = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest}
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest;
  return proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest}
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
configOverridesMap: (f = msg.getConfigOverridesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest}
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest;
  return proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest}
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 2:
      var value = msg.getConfigOverridesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfigOverridesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> config_overrides = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.prototype.getConfigOverridesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesRequest.prototype.clearConfigOverridesMap = function() {
  this.getConfigOverridesMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse}
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse;
  return proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse}
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.SetConfigOverridesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
configOverridesMap: (f = msg.getConfigOverridesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse}
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse;
  return proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse}
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getConfigOverridesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigOverridesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> config_overrides = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse.prototype.getConfigOverridesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetConfigOverridesResponse.prototype.clearConfigOverridesMap = function() {
  this.getConfigOverridesMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ServiceWindow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ServiceWindow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
dayOfWeek: jspb.Message.getFieldWithDefault(msg, 2, 0),
startHourOfDay: jspb.Message.getFieldWithDefault(msg, 3, 0),
endHourOfDay: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ServiceWindow}
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ServiceWindow;
  return proto.ld.services.hosted.v1alpha1.ServiceWindow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ServiceWindow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ServiceWindow}
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.google.type.DayOfWeek} */ (reader.readEnum());
      msg.setDayOfWeek(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartHourOfDay(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndHourOfDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ServiceWindow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ServiceWindow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDayOfWeek();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStartHourOfDay();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getEndHourOfDay();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ServiceWindow} returns this
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.DayOfWeek day_of_week = 2;
 * @return {!proto.google.type.DayOfWeek}
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.prototype.getDayOfWeek = function() {
  return /** @type {!proto.google.type.DayOfWeek} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.google.type.DayOfWeek} value
 * @return {!proto.ld.services.hosted.v1alpha1.ServiceWindow} returns this
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.prototype.setDayOfWeek = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 start_hour_of_day = 3;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.prototype.getStartHourOfDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.ServiceWindow} returns this
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.prototype.setStartHourOfDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 end_hour_of_day = 4;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.prototype.getEndHourOfDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.ServiceWindow} returns this
 */
proto.ld.services.hosted.v1alpha1.ServiceWindow.prototype.setEndHourOfDay = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest;
  return proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
serviceWindowsList: jspb.Message.toObjectList(msg.getServiceWindowsList(),
    proto.ld.services.hosted.v1alpha1.ServiceWindow.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse}
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse;
  return proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse}
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.ServiceWindow;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.ServiceWindow.deserializeBinaryFromReader);
      msg.addServiceWindows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceWindowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.ServiceWindow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ServiceWindow service_windows = 1;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.ServiceWindow>}
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.prototype.getServiceWindowsList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.ServiceWindow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.ServiceWindow, 1));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.ServiceWindow>} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.prototype.setServiceWindowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.ServiceWindow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.ServiceWindow}
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.prototype.addServiceWindows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hosted.v1alpha1.ServiceWindow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetServiceWindowsResponse.prototype.clearServiceWindowsList = function() {
  return this.setServiceWindowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
serviceWindow: (f = msg.getServiceWindow()) && proto.ld.services.hosted.v1alpha1.ServiceWindow.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest;
  return proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.ServiceWindow;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.ServiceWindow.deserializeBinaryFromReader);
      msg.setServiceWindow(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceWindow();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.ServiceWindow.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServiceWindow service_window = 1;
 * @return {?proto.ld.services.hosted.v1alpha1.ServiceWindow}
 */
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.prototype.getServiceWindow = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.ServiceWindow} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.ServiceWindow, 1));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.ServiceWindow|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.prototype.setServiceWindow = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.prototype.clearServiceWindow = function() {
  return this.setServiceWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.prototype.hasServiceWindow = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.UpdateServiceWindowRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest;
  return proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
versionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse;
  return proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string versions = 1;
 * @return {!Array<string>}
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.prototype.getVersionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.prototype.setVersionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.prototype.addVersions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDoltVersionsResponse.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
instanceTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
volumeTypeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
volumeSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
backupName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest}
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest;
  return proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest}
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceTypeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolumeTypeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolumeSize(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackupName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstanceTypeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVolumeTypeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVolumeSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBackupName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string instance_type_id = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.prototype.getInstanceTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.prototype.setInstanceTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string volume_type_id = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.prototype.getVolumeTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.prototype.setVolumeTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 volume_size = 4;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.prototype.getVolumeSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.prototype.setVolumeSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string backup_name = 5;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.prototype.getBackupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentRequest.prototype.setBackupName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse}
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse;
  return proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse}
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.AddInstanceToDeploymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
instanceName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest}
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest;
  return proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest}
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstanceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string instance_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest.prototype.getInstanceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentRequest.prototype.setInstanceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse}
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse;
  return proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse}
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeleteInstanceFromDeploymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.RebootInstanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.RebootInstanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
instanceName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.RebootInstanceRequest}
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.RebootInstanceRequest;
  return proto.ld.services.hosted.v1alpha1.RebootInstanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.RebootInstanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.RebootInstanceRequest}
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.RebootInstanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.RebootInstanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstanceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string instance_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceRequest.prototype.getInstanceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.RebootInstanceRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceRequest.prototype.setInstanceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.RebootInstanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.RebootInstanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.RebootInstanceResponse}
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.RebootInstanceResponse;
  return proto.ld.services.hosted.v1alpha1.RebootInstanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.RebootInstanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.RebootInstanceResponse}
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.RebootInstanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.RebootInstanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.RebootInstanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.RestartDoltRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.RestartDoltRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.RestartDoltRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.RestartDoltRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
instanceName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.RestartDoltRequest}
 */
proto.ld.services.hosted.v1alpha1.RestartDoltRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.RestartDoltRequest;
  return proto.ld.services.hosted.v1alpha1.RestartDoltRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.RestartDoltRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.RestartDoltRequest}
 */
proto.ld.services.hosted.v1alpha1.RestartDoltRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.RestartDoltRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.RestartDoltRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.RestartDoltRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.RestartDoltRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstanceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string instance_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.RestartDoltRequest.prototype.getInstanceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.RestartDoltRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.RestartDoltRequest.prototype.setInstanceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.RestartDoltResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.RestartDoltResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.RestartDoltResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.RestartDoltResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.RestartDoltResponse}
 */
proto.ld.services.hosted.v1alpha1.RestartDoltResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.RestartDoltResponse;
  return proto.ld.services.hosted.v1alpha1.RestartDoltResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.RestartDoltResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.RestartDoltResponse}
 */
proto.ld.services.hosted.v1alpha1.RestartDoltResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.RestartDoltResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.RestartDoltResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.RestartDoltResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.RestartDoltResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateBackupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateBackupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateBackupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateBackupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateBackupRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateBackupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateBackupRequest;
  return proto.ld.services.hosted.v1alpha1.CreateBackupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateBackupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateBackupRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateBackupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateBackupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateBackupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateBackupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateBackupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateBackupRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateBackupRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateBackupRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateBackupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateBackupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateBackupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateBackupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateBackupResponse}
 */
proto.ld.services.hosted.v1alpha1.CreateBackupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateBackupResponse;
  return proto.ld.services.hosted.v1alpha1.CreateBackupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateBackupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateBackupResponse}
 */
proto.ld.services.hosted.v1alpha1.CreateBackupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateBackupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateBackupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateBackupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateBackupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDoltRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
desiredVersion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDoltRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.UpdateDoltRequest;
  return proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDoltRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDoltRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesiredVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDoltRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDesiredVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDoltRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string desired_version = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.prototype.getDesiredVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDoltRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltRequest.prototype.setDesiredVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.UpdateDoltResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDoltResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDoltResponse}
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.UpdateDoltResponse;
  return proto.ld.services.hosted.v1alpha1.UpdateDoltResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDoltResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDoltResponse}
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.UpdateDoltResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDoltResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateDoltResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
exposeRemotesapiEndpoint: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest;
  return proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExposeRemotesapiEndpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExposeRemotesapiEndpoint();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool expose_remotesapi_endpoint = 2;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.prototype.getExposeRemotesapiEndpoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointRequest.prototype.setExposeRemotesapiEndpoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse}
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse;
  return proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse}
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateExposeRemotesapiEndpointResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
configOverridesMap: (f = msg.getConfigOverridesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest;
  return proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 2:
      var value = msg.getConfigOverridesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfigOverridesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> config_overrides = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.prototype.getConfigOverridesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesRequest.prototype.clearConfigOverridesMap = function() {
  this.getConfigOverridesMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse}
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse;
  return proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse}
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateConfigOverridesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.WorkbenchSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.WorkbenchSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.WorkbenchSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.WorkbenchSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
deleteSourceBranchOnAcceptedMerge: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
enableWorkbenchWrites: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.WorkbenchSettings}
 */
proto.ld.services.hosted.v1alpha1.WorkbenchSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.WorkbenchSettings;
  return proto.ld.services.hosted.v1alpha1.WorkbenchSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.WorkbenchSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.WorkbenchSettings}
 */
proto.ld.services.hosted.v1alpha1.WorkbenchSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleteSourceBranchOnAcceptedMerge(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableWorkbenchWrites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.WorkbenchSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.WorkbenchSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.WorkbenchSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.WorkbenchSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeleteSourceBranchOnAcceptedMerge();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getEnableWorkbenchWrites();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.WorkbenchSettings.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.WorkbenchSettings} returns this
 */
proto.ld.services.hosted.v1alpha1.WorkbenchSettings.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool delete_source_branch_on_accepted_merge = 2;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.WorkbenchSettings.prototype.getDeleteSourceBranchOnAcceptedMerge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.WorkbenchSettings} returns this
 */
proto.ld.services.hosted.v1alpha1.WorkbenchSettings.prototype.setDeleteSourceBranchOnAcceptedMerge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool enable_workbench_writes = 3;
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.WorkbenchSettings.prototype.getEnableWorkbenchWrites = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ld.services.hosted.v1alpha1.WorkbenchSettings} returns this
 */
proto.ld.services.hosted.v1alpha1.WorkbenchSettings.prototype.setEnableWorkbenchWrites = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
workbenchSettings: (f = msg.getWorkbenchSettings()) && proto.ld.services.hosted.v1alpha1.WorkbenchSettings.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest;
  return proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.WorkbenchSettings;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.WorkbenchSettings.deserializeBinaryFromReader);
      msg.setWorkbenchSettings(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkbenchSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.WorkbenchSettings.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional WorkbenchSettings workbench_settings = 1;
 * @return {?proto.ld.services.hosted.v1alpha1.WorkbenchSettings}
 */
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.prototype.getWorkbenchSettings = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.WorkbenchSettings} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.WorkbenchSettings, 1));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.WorkbenchSettings|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.prototype.setWorkbenchSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.prototype.clearWorkbenchSettings = function() {
  return this.setWorkbenchSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.prototype.hasWorkbenchSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.UpdateWorkbenchSettingsRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest;
  return proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetWorkbenchSettingsRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
currentPrimary: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest}
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest;
  return proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest}
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentPrimary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentPrimary();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string current_primary = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest.prototype.getCurrentPrimary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryRequest.prototype.setCurrentPrimary = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse}
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse;
  return proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse}
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ChangePrimaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
doltVersion: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest;
  return proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoltVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoltVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dolt_version = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest.prototype.getDoltVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionRequest.prototype.setDoltVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse}
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse;
  return proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse}
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDoltVersionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.CloudProvider = {
  CLOUD_PROVIDER_UNSPECIFIED: 0,
  CLOUD_PROVIDER_AWS: 1,
  CLOUD_PROVIDER_GCP: 2
};

/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.DeploymentState = {
  DEPLOYMENT_STATE_UNSPECIFIED: 0,
  DEPLOYMENT_STATE_STARTING: 1,
  DEPLOYMENT_STATE_STARTED: 2,
  DEPLOYMENT_STATE_STOPPING: 3,
  DEPLOYMENT_STATE_STOPPED: 4
};

/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.DeploymentInstanceState = {
  DEPLOYMENT_INSTANCE_STATE_UNSPECIFIED: 0,
  DEPLOYMENT_INSTANCE_STATE_STARTING: 1,
  DEPLOYMENT_INSTANCE_STATE_STARTED: 2,
  DEPLOYMENT_INSTANCE_STATE_STOPPING: 3,
  DEPLOYMENT_INSTANCE_STATE_STOPPED: 4
};

/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.ClusterType = {
  CLUSTER_TYPE_DOLT: 0,
  CLUSTER_TYPE_DOLTGRES: 1,
  CLUSTER_TYPE_MYSQL_WITH_DOLT_REPLICAS: 2
};

/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.Application = {
  APPLICATION_DOLT: 0,
  APPLICATION_DOLTGRES: 1,
  APPLICATION_MYSQL: 2
};

/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.DeploymentFlag = {
  DEPLOYMENT_FLAG_NONE: 0,
  DEPLOYMENT_FLAG_UI_TEST: 1
};

/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.TimePeriod = {
  TIME_PERIOD_UNSPECIFIED: 0,
  TIME_PERIOD_HOUR: 1,
  TIME_PERIOD_MONTH: 2
};

/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.DeploymentRole = {
  DEPLOYMENT_ROLE_UNSPECIFIED: 0,
  DEPLOYMENT_ROLE_ADMIN: 1,
  DEPLOYMENT_ROLE_READER: 2,
  DEPLOYMENT_ROLE_WRITER: 3
};

/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.PrivateLinkEndpointState = {
  PRIVATE_LINK_ENDPOINT_STATE_UNSPECIFIED: 0,
  PRIVATE_LINK_ENDPOINT_STATE_CREATING: 1,
  PRIVATE_LINK_ENDPOINT_STATE_AVAILABLE: 2,
  PRIVATE_LINK_ENDPOINT_STATE_DELETED: 3
};

/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.CollaboratorPermissionLevel = {
  COLLABORATOR_PERMISSION_LEVEL_UNSPECIFIED: 0,
  COLLABORATOR_PERMISSION_LEVEL_ADMIN: 1,
  COLLABORATOR_PERMISSION_LEVEL_READ: 2,
  COLLABORATOR_PERMISSION_LEVEL_READ_AND_PULLS: 3,
  COLLABORATOR_PERMISSION_LEVEL_WRITE: 4
};

/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.OverrideTypes = {
  OVERRIDE_TYPE_UNSPECIFIED: 0,
  OVERRIDE_TYPE_STRING: 1,
  OVERRIDE_TYPE_INT: 2
};

goog.object.extend(exports, proto.ld.services.hosted.v1alpha1);
