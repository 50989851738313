// source: ld/services/hostedapi/v1alpha1/pull.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var ld_rpc_request_logging_pb = require('../../../../ld/rpc/request_logging_pb.js');
goog.object.extend(proto, ld_rpc_request_logging_pb);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.BranchPullState', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.CreatePullRequest', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.ListPullsRequest', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.ListPullsResponse', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.MergeState', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.Pull', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.PullAction', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.PullActivity', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.PullActivityLog', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.PullComment', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.PullCommentAction', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.PullDiffSummary', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.PullState', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest', null, global);
goog.exportSymbol('proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.Pull = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hostedapi.v1alpha1.Pull.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.Pull, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.Pull.displayName = 'proto.ld.services.hostedapi.v1alpha1.Pull';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.CreatePullRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.displayName = 'proto.ld.services.hostedapi.v1alpha1.CreatePullRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.ListPullsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.displayName = 'proto.ld.services.hostedapi.v1alpha1.ListPullsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.ListPullsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.displayName = 'proto.ld.services.hostedapi.v1alpha1.ListPullsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.MergeState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.MergeState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.MergeState.displayName = 'proto.ld.services.hostedapi.v1alpha1.MergeState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.PullActivityLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.PullActivityLog.displayName = 'proto.ld.services.hostedapi.v1alpha1.PullActivityLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.PullDiffSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.displayName = 'proto.ld.services.hostedapi.v1alpha1.PullDiffSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest.displayName = 'proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.displayName = 'proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.displayName = 'proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse.displayName = 'proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.displayName = 'proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.PullComment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hostedapi.v1alpha1.PullComment.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.PullComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.PullComment.displayName = 'proto.ld.services.hostedapi.v1alpha1.PullComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.displayName = 'proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.displayName = 'proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest.displayName = 'proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.displayName = 'proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.BranchPullState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.BranchPullState.displayName = 'proto.ld.services.hostedapi.v1alpha1.BranchPullState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.displayName = 'proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hostedapi.v1alpha1.Pull.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.Pull.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.Pull} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.Pull.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
databaseName: jspb.Message.getFieldWithDefault(msg, 2, ""),
title: jspb.Message.getFieldWithDefault(msg, 3, ""),
description: jspb.Message.getFieldWithDefault(msg, 4, ""),
fromBranch: jspb.Message.getFieldWithDefault(msg, 5, ""),
toBranch: jspb.Message.getFieldWithDefault(msg, 6, ""),
state: jspb.Message.getFieldWithDefault(msg, 7, 0),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
creator: jspb.Message.getFieldWithDefault(msg, 9, ""),
availableCallerActionsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
mergeState: (f = msg.getMergeState()) && proto.ld.services.hostedapi.v1alpha1.MergeState.toObject(includeInstance, f),
commentCount: jspb.Message.getFieldWithDefault(msg, 13, 0),
afterMergeCommit: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.Pull;
  return proto.ld.services.hostedapi.v1alpha1.Pull.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.Pull} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatabaseName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromBranch(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setToBranch(value);
      break;
    case 7:
      var value = /** @type {!proto.ld.services.hostedapi.v1alpha1.PullState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    case 10:
      var values = /** @type {!Array<!proto.ld.services.hostedapi.v1alpha1.PullAction>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAvailableCallerActions(values[i]);
      }
      break;
    case 11:
      var value = new proto.ld.services.hostedapi.v1alpha1.MergeState;
      reader.readMessage(value,proto.ld.services.hostedapi.v1alpha1.MergeState.deserializeBinaryFromReader);
      msg.setMergeState(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCommentCount(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAfterMergeCommit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.Pull.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.Pull} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.Pull.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDatabaseName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFromBranch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getToBranch();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAvailableCallerActionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
  f = message.getMergeState();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.ld.services.hostedapi.v1alpha1.MergeState.serializeBinaryToWriter
    );
  }
  f = message.getCommentCount();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getAfterMergeCommit();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string database_name = 2;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.getDatabaseName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.setDatabaseName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string from_branch = 5;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.getFromBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.setFromBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string to_branch = 6;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.getToBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.setToBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional PullState state = 7;
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullState}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.getState = function() {
  return /** @type {!proto.ld.services.hostedapi.v1alpha1.PullState} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullState} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
*/
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string creator = 9;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated PullAction available_caller_actions = 10;
 * @return {!Array<!proto.ld.services.hostedapi.v1alpha1.PullAction>}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.getAvailableCallerActionsList = function() {
  return /** @type {!Array<!proto.ld.services.hostedapi.v1alpha1.PullAction>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.ld.services.hostedapi.v1alpha1.PullAction>} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.setAvailableCallerActionsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullAction} value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.addAvailableCallerActions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.clearAvailableCallerActionsList = function() {
  return this.setAvailableCallerActionsList([]);
};


/**
 * optional MergeState merge_state = 11;
 * @return {?proto.ld.services.hostedapi.v1alpha1.MergeState}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.getMergeState = function() {
  return /** @type{?proto.ld.services.hostedapi.v1alpha1.MergeState} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hostedapi.v1alpha1.MergeState, 11));
};


/**
 * @param {?proto.ld.services.hostedapi.v1alpha1.MergeState|undefined} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
*/
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.setMergeState = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.clearMergeState = function() {
  return this.setMergeState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.hasMergeState = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 comment_count = 13;
 * @return {number}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.getCommentCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.setCommentCount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string after_merge_commit = 14;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.getAfterMergeCommit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull} returns this
 */
proto.ld.services.hostedapi.v1alpha1.Pull.prototype.setAfterMergeCommit = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.CreatePullRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
pull: (f = msg.getPull()) && proto.ld.services.hostedapi.v1alpha1.Pull.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.CreatePullRequest}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.CreatePullRequest;
  return proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.CreatePullRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.CreatePullRequest}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 2:
      var value = new proto.ld.services.hostedapi.v1alpha1.Pull;
      reader.readMessage(value,proto.ld.services.hostedapi.v1alpha1.Pull.deserializeBinaryFromReader);
      msg.setPull(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.CreatePullRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPull();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ld.services.hostedapi.v1alpha1.Pull.serializeBinaryToWriter
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.CreatePullRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Pull pull = 2;
 * @return {?proto.ld.services.hostedapi.v1alpha1.Pull}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.prototype.getPull = function() {
  return /** @type{?proto.ld.services.hostedapi.v1alpha1.Pull} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hostedapi.v1alpha1.Pull, 2));
};


/**
 * @param {?proto.ld.services.hostedapi.v1alpha1.Pull|undefined} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.CreatePullRequest} returns this
*/
proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.prototype.setPull = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hostedapi.v1alpha1.CreatePullRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.prototype.clearPull = function() {
  return this.setPull(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullRequest.prototype.hasPull = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.ListPullsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
databaseName: jspb.Message.getFieldWithDefault(msg, 2, ""),
pageToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
filterByPullState: jspb.Message.getFieldWithDefault(msg, 4, 0),
query: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullsRequest}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.ListPullsRequest;
  return proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.ListPullsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullsRequest}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatabaseName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 4:
      var value = /** @type {!proto.ld.services.hostedapi.v1alpha1.PullState} */ (reader.readEnum());
      msg.setFilterByPullState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.ListPullsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDatabaseName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilterByPullState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullsRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string database_name = 2;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.prototype.getDatabaseName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullsRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.prototype.setDatabaseName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullsRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PullState filter_by_pull_state = 4;
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullState}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.prototype.getFilterByPullState = function() {
  return /** @type {!proto.ld.services.hostedapi.v1alpha1.PullState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullState} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullsRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.prototype.setFilterByPullState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string query = 5;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullsRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.ListPullsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
pullsList: jspb.Message.toObjectList(msg.getPullsList(),
    proto.ld.services.hostedapi.v1alpha1.Pull.toObject, includeInstance),
nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullsResponse}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.ListPullsResponse;
  return proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.ListPullsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullsResponse}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hostedapi.v1alpha1.Pull;
      reader.readMessage(value,proto.ld.services.hostedapi.v1alpha1.Pull.deserializeBinaryFromReader);
      msg.addPulls(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.ListPullsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPullsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hostedapi.v1alpha1.Pull.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Pull pulls = 1;
 * @return {!Array<!proto.ld.services.hostedapi.v1alpha1.Pull>}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.prototype.getPullsList = function() {
  return /** @type{!Array<!proto.ld.services.hostedapi.v1alpha1.Pull>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hostedapi.v1alpha1.Pull, 1));
};


/**
 * @param {!Array<!proto.ld.services.hostedapi.v1alpha1.Pull>} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullsResponse} returns this
*/
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.prototype.setPullsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hostedapi.v1alpha1.Pull=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hostedapi.v1alpha1.Pull}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.prototype.addPulls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hostedapi.v1alpha1.Pull, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullsResponse} returns this
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.prototype.clearPullsList = function() {
  return this.setPullsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullsResponse} returns this
 */
proto.ld.services.hostedapi.v1alpha1.ListPullsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.MergeState.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.MergeState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.MergeState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.MergeState.toObject = function(includeInstance, msg) {
  var f, obj = {
premergeToCommit: jspb.Message.getFieldWithDefault(msg, 1, ""),
premergeFromCommit: jspb.Message.getFieldWithDefault(msg, 2, ""),
mergeBaseCommit: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.MergeState}
 */
proto.ld.services.hostedapi.v1alpha1.MergeState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.MergeState;
  return proto.ld.services.hostedapi.v1alpha1.MergeState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.MergeState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.MergeState}
 */
proto.ld.services.hostedapi.v1alpha1.MergeState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPremergeToCommit(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPremergeFromCommit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMergeBaseCommit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.MergeState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.MergeState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.MergeState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.MergeState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPremergeToCommit();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPremergeFromCommit();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMergeBaseCommit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string premerge_to_commit = 1;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.MergeState.prototype.getPremergeToCommit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.MergeState} returns this
 */
proto.ld.services.hostedapi.v1alpha1.MergeState.prototype.setPremergeToCommit = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string premerge_from_commit = 2;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.MergeState.prototype.getPremergeFromCommit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.MergeState} returns this
 */
proto.ld.services.hostedapi.v1alpha1.MergeState.prototype.setPremergeFromCommit = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string merge_base_commit = 3;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.MergeState.prototype.getMergeBaseCommit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.MergeState} returns this
 */
proto.ld.services.hostedapi.v1alpha1.MergeState.prototype.setMergeBaseCommit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.PullActivityLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullActivityLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
activity: jspb.Message.getFieldWithDefault(msg, 2, 0),
loggingUser: jspb.Message.getFieldWithDefault(msg, 3, ""),
loggedAt: (f = msg.getLoggedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullActivityLog}
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.PullActivityLog;
  return proto.ld.services.hostedapi.v1alpha1.PullActivityLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullActivityLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullActivityLog}
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.ld.services.hostedapi.v1alpha1.PullActivity} */ (reader.readEnum());
      msg.setActivity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoggingUser(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLoggedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.PullActivityLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullActivityLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivity();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLoggingUser();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLoggedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullActivityLog} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PullActivity activity = 2;
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullActivity}
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.prototype.getActivity = function() {
  return /** @type {!proto.ld.services.hostedapi.v1alpha1.PullActivity} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullActivity} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullActivityLog} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.prototype.setActivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string logging_user = 3;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.prototype.getLoggingUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullActivityLog} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.prototype.setLoggingUser = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp logged_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.prototype.getLoggedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullActivityLog} returns this
*/
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.prototype.setLoggedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullActivityLog} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.prototype.clearLoggedAt = function() {
  return this.setLoggedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hostedapi.v1alpha1.PullActivityLog.prototype.hasLoggedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullDiffSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
commentsList: jspb.Message.toObjectList(msg.getCommentsList(),
    proto.ld.services.hostedapi.v1alpha1.PullComment.toObject, includeInstance),
activityLogsList: jspb.Message.toObjectList(msg.getActivityLogsList(),
    proto.ld.services.hostedapi.v1alpha1.PullActivityLog.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullDiffSummary}
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.PullDiffSummary;
  return proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullDiffSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullDiffSummary}
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hostedapi.v1alpha1.PullComment;
      reader.readMessage(value,proto.ld.services.hostedapi.v1alpha1.PullComment.deserializeBinaryFromReader);
      msg.addComments(value);
      break;
    case 3:
      var value = new proto.ld.services.hostedapi.v1alpha1.PullActivityLog;
      reader.readMessage(value,proto.ld.services.hostedapi.v1alpha1.PullActivityLog.deserializeBinaryFromReader);
      msg.addActivityLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullDiffSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hostedapi.v1alpha1.PullComment.serializeBinaryToWriter
    );
  }
  f = message.getActivityLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ld.services.hostedapi.v1alpha1.PullActivityLog.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PullComment comments = 1;
 * @return {!Array<!proto.ld.services.hostedapi.v1alpha1.PullComment>}
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.prototype.getCommentsList = function() {
  return /** @type{!Array<!proto.ld.services.hostedapi.v1alpha1.PullComment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hostedapi.v1alpha1.PullComment, 1));
};


/**
 * @param {!Array<!proto.ld.services.hostedapi.v1alpha1.PullComment>} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullDiffSummary} returns this
*/
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.prototype.setCommentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullComment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullComment}
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.prototype.addComments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hostedapi.v1alpha1.PullComment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullDiffSummary} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.prototype.clearCommentsList = function() {
  return this.setCommentsList([]);
};


/**
 * repeated PullActivityLog activity_logs = 3;
 * @return {!Array<!proto.ld.services.hostedapi.v1alpha1.PullActivityLog>}
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.prototype.getActivityLogsList = function() {
  return /** @type{!Array<!proto.ld.services.hostedapi.v1alpha1.PullActivityLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hostedapi.v1alpha1.PullActivityLog, 3));
};


/**
 * @param {!Array<!proto.ld.services.hostedapi.v1alpha1.PullActivityLog>} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullDiffSummary} returns this
*/
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.prototype.setActivityLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullActivityLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullActivityLog}
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.prototype.addActivityLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ld.services.hostedapi.v1alpha1.PullActivityLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullDiffSummary} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.prototype.clearActivityLogsList = function() {
  return this.setActivityLogsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest;
  return proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
pull: (f = msg.getPull()) && proto.ld.services.hostedapi.v1alpha1.Pull.toObject(includeInstance, f),
diffSummary: (f = msg.getDiffSummary()) && proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse;
  return proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hostedapi.v1alpha1.Pull;
      reader.readMessage(value,proto.ld.services.hostedapi.v1alpha1.Pull.deserializeBinaryFromReader);
      msg.setPull(value);
      break;
    case 2:
      var value = new proto.ld.services.hostedapi.v1alpha1.PullDiffSummary;
      reader.readMessage(value,proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.deserializeBinaryFromReader);
      msg.setDiffSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPull();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ld.services.hostedapi.v1alpha1.Pull.serializeBinaryToWriter
    );
  }
  f = message.getDiffSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ld.services.hostedapi.v1alpha1.PullDiffSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional Pull pull = 1;
 * @return {?proto.ld.services.hostedapi.v1alpha1.Pull}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.prototype.getPull = function() {
  return /** @type{?proto.ld.services.hostedapi.v1alpha1.Pull} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hostedapi.v1alpha1.Pull, 1));
};


/**
 * @param {?proto.ld.services.hostedapi.v1alpha1.Pull|undefined} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse} returns this
*/
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.prototype.setPull = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse} returns this
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.prototype.clearPull = function() {
  return this.setPull(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.prototype.hasPull = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PullDiffSummary diff_summary = 2;
 * @return {?proto.ld.services.hostedapi.v1alpha1.PullDiffSummary}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.prototype.getDiffSummary = function() {
  return /** @type{?proto.ld.services.hostedapi.v1alpha1.PullDiffSummary} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hostedapi.v1alpha1.PullDiffSummary, 2));
};


/**
 * @param {?proto.ld.services.hostedapi.v1alpha1.PullDiffSummary|undefined} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse} returns this
*/
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.prototype.setDiffSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse} returns this
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.prototype.clearDiffSummary = function() {
  return this.setDiffSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullDetailsResponse.prototype.hasDiffSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
fromBranch: jspb.Message.getFieldWithDefault(msg, 1, ""),
toBranch: jspb.Message.getFieldWithDefault(msg, 2, ""),
databaseName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest;
  return proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromBranch(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToBranch(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatabaseName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromBranch();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToBranch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDatabaseName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string from_branch = 1;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.prototype.getFromBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.prototype.setFromBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to_branch = 2;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.prototype.getToBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.prototype.setToBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string database_name = 3;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.prototype.getDatabaseName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewRequest.prototype.setDatabaseName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
existingPull: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse;
  return proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExistingPull(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExistingPull();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string existing_pull = 1;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse.prototype.getExistingPull = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse} returns this
 */
proto.ld.services.hostedapi.v1alpha1.GetPullPreviewResponse.prototype.setExistingPull = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
pull: (f = msg.getPull()) && proto.ld.services.hostedapi.v1alpha1.Pull.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f),
updatedPullActivity: jspb.Message.getFieldWithDefault(msg, 3, 0),
associatedUser: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest;
  return proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hostedapi.v1alpha1.Pull;
      reader.readMessage(value,proto.ld.services.hostedapi.v1alpha1.Pull.deserializeBinaryFromReader);
      msg.setPull(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    case 3:
      var value = /** @type {!proto.ld.services.hostedapi.v1alpha1.PullActivity} */ (reader.readEnum());
      msg.setUpdatedPullActivity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPull();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ld.services.hostedapi.v1alpha1.Pull.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedPullActivity();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAssociatedUser();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Pull pull = 1;
 * @return {?proto.ld.services.hostedapi.v1alpha1.Pull}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.getPull = function() {
  return /** @type{?proto.ld.services.hostedapi.v1alpha1.Pull} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hostedapi.v1alpha1.Pull, 1));
};


/**
 * @param {?proto.ld.services.hostedapi.v1alpha1.Pull|undefined} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest} returns this
*/
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.setPull = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.clearPull = function() {
  return this.setPull(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.hasPull = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest} returns this
*/
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PullActivity updated_pull_activity = 3;
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullActivity}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.getUpdatedPullActivity = function() {
  return /** @type {!proto.ld.services.hostedapi.v1alpha1.PullActivity} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullActivity} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.setUpdatedPullActivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string associated_user = 4;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.getAssociatedUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullRequest.prototype.setAssociatedUser = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.PullComment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullComment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
author: jspb.Message.getFieldWithDefault(msg, 2, ""),
comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
availableCallerActionsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullComment}
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.PullComment;
  return proto.ld.services.hostedapi.v1alpha1.PullComment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullComment}
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.ld.services.hostedapi.v1alpha1.PullCommentAction>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAvailableCallerActions(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.PullComment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAvailableCallerActionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullComment} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string author = 2;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.getAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullComment} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.setAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullComment} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullComment} returns this
*/
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullComment} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullComment} returns this
*/
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullComment} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated PullCommentAction available_caller_actions = 6;
 * @return {!Array<!proto.ld.services.hostedapi.v1alpha1.PullCommentAction>}
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.getAvailableCallerActionsList = function() {
  return /** @type {!Array<!proto.ld.services.hostedapi.v1alpha1.PullCommentAction>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.ld.services.hostedapi.v1alpha1.PullCommentAction>} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullComment} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.setAvailableCallerActionsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullCommentAction} value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullComment} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.addAvailableCallerActions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullComment} returns this
 */
proto.ld.services.hostedapi.v1alpha1.PullComment.prototype.clearAvailableCallerActionsList = function() {
  return this.setAvailableCallerActionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
comment: (f = msg.getComment()) && proto.ld.services.hostedapi.v1alpha1.PullComment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest;
  return proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 2:
      var value = new proto.ld.services.hostedapi.v1alpha1.PullComment;
      reader.readMessage(value,proto.ld.services.hostedapi.v1alpha1.PullComment.deserializeBinaryFromReader);
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ld.services.hostedapi.v1alpha1.PullComment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PullComment comment = 2;
 * @return {?proto.ld.services.hostedapi.v1alpha1.PullComment}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.prototype.getComment = function() {
  return /** @type{?proto.ld.services.hostedapi.v1alpha1.PullComment} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hostedapi.v1alpha1.PullComment, 2));
};


/**
 * @param {?proto.ld.services.hostedapi.v1alpha1.PullComment|undefined} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest} returns this
*/
proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.prototype.setComment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.prototype.clearComment = function() {
  return this.setComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hostedapi.v1alpha1.CreatePullCommentRequest.prototype.hasComment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
comment: (f = msg.getComment()) && proto.ld.services.hostedapi.v1alpha1.PullComment.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest;
  return proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hostedapi.v1alpha1.PullComment;
      reader.readMessage(value,proto.ld.services.hostedapi.v1alpha1.PullComment.deserializeBinaryFromReader);
      msg.setComment(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ld.services.hostedapi.v1alpha1.PullComment.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional PullComment comment = 1;
 * @return {?proto.ld.services.hostedapi.v1alpha1.PullComment}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.prototype.getComment = function() {
  return /** @type{?proto.ld.services.hostedapi.v1alpha1.PullComment} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hostedapi.v1alpha1.PullComment, 1));
};


/**
 * @param {?proto.ld.services.hostedapi.v1alpha1.PullComment|undefined} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest} returns this
*/
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.prototype.setComment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.prototype.clearComment = function() {
  return this.setComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.prototype.hasComment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest} returns this
*/
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hostedapi.v1alpha1.UpdatePullCommentRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest}
 */
proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest;
  return proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest}
 */
proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.DeletePullCommentRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
branchNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
databaseName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest;
  return proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addBranchNames(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatabaseName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getDatabaseName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string branch_names = 1;
 * @return {!Array<string>}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.prototype.getBranchNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.prototype.setBranchNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.prototype.addBranchNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.prototype.clearBranchNamesList = function() {
  return this.setBranchNamesList([]);
};


/**
 * optional string database_name = 2;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.prototype.getDatabaseName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest} returns this
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesRequest.prototype.setDatabaseName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.BranchPullState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.BranchPullState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.toObject = function(includeInstance, msg) {
  var f, obj = {
branchName: jspb.Message.getFieldWithDefault(msg, 1, ""),
pullName: jspb.Message.getFieldWithDefault(msg, 2, ""),
databaseName: jspb.Message.getFieldWithDefault(msg, 3, ""),
state: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.BranchPullState}
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.BranchPullState;
  return proto.ld.services.hostedapi.v1alpha1.BranchPullState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.BranchPullState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.BranchPullState}
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPullName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatabaseName(value);
      break;
    case 4:
      var value = /** @type {!proto.ld.services.hostedapi.v1alpha1.PullState} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.BranchPullState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.BranchPullState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDatabaseName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string branch_name = 1;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.prototype.getBranchName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.BranchPullState} returns this
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.prototype.setBranchName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pull_name = 2;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.prototype.getPullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.BranchPullState} returns this
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.prototype.setPullName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string database_name = 3;
 * @return {string}
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.prototype.getDatabaseName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.BranchPullState} returns this
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.prototype.setDatabaseName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PullState state = 4;
 * @return {!proto.ld.services.hostedapi.v1alpha1.PullState}
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.prototype.getState = function() {
  return /** @type {!proto.ld.services.hostedapi.v1alpha1.PullState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.ld.services.hostedapi.v1alpha1.PullState} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.BranchPullState} returns this
 */
proto.ld.services.hostedapi.v1alpha1.BranchPullState.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
branchPullStateList: jspb.Message.toObjectList(msg.getBranchPullStateList(),
    proto.ld.services.hostedapi.v1alpha1.BranchPullState.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse;
  return proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hostedapi.v1alpha1.BranchPullState;
      reader.readMessage(value,proto.ld.services.hostedapi.v1alpha1.BranchPullState.deserializeBinaryFromReader);
      msg.addBranchPullState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchPullStateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hostedapi.v1alpha1.BranchPullState.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BranchPullState branch_pull_state = 1;
 * @return {!Array<!proto.ld.services.hostedapi.v1alpha1.BranchPullState>}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.prototype.getBranchPullStateList = function() {
  return /** @type{!Array<!proto.ld.services.hostedapi.v1alpha1.BranchPullState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hostedapi.v1alpha1.BranchPullState, 1));
};


/**
 * @param {!Array<!proto.ld.services.hostedapi.v1alpha1.BranchPullState>} value
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse} returns this
*/
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.prototype.setBranchPullStateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hostedapi.v1alpha1.BranchPullState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hostedapi.v1alpha1.BranchPullState}
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.prototype.addBranchPullState = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hostedapi.v1alpha1.BranchPullState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse} returns this
 */
proto.ld.services.hostedapi.v1alpha1.ListPullStatesForBranchesResponse.prototype.clearBranchPullStateList = function() {
  return this.setBranchPullStateList([]);
};


/**
 * @enum {number}
 */
proto.ld.services.hostedapi.v1alpha1.PullAction = {
  PULL_ACTION_NONE: 0,
  PULL_ACTION_CLOSE: 1,
  PULL_ACTION_MERGE: 2
};

/**
 * @enum {number}
 */
proto.ld.services.hostedapi.v1alpha1.PullState = {
  PULL_STATE_UNSPECIFIED: 0,
  PULL_STATE_OPEN: 1,
  PULL_STATE_CLOSED: 2,
  PULL_STATE_MERGED: 3
};

/**
 * @enum {number}
 */
proto.ld.services.hostedapi.v1alpha1.PullActivity = {
  PULL_ACTIVITY_UNSPECIFIED: 0,
  PULL_ACTIVITY_OPENED: 1,
  PULL_ACTIVITY_MERGED: 2,
  PULL_ACTIVITY_CLOSED: 3,
  PULL_ACTIVITY_BRANCH_DELETED: 4,
  PULL_ACTIVITY_DATABASE_DROPPED: 5
};

/**
 * @enum {number}
 */
proto.ld.services.hostedapi.v1alpha1.PullCommentAction = {
  PULL_COMMENT_ACTION_UNSPECIFIED: 0,
  PULL_COMMENT_ACTION_UPDATE: 1,
  PULL_COMMENT_ACTION_DELETE: 2
};

goog.object.extend(exports, proto.ld.services.hostedapi.v1alpha1);
