// package: ld.services.hostedapi.v1alpha1
// file: ld/services/hostedapi/v1alpha1/pull.proto

var ld_services_hostedapi_v1alpha1_pull_pb = require("../../../../ld/services/hostedapi/v1alpha1/pull_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var PullService = (function () {
  function PullService() {}
  PullService.serviceName = "ld.services.hostedapi.v1alpha1.PullService";
  return PullService;
}());

PullService.CreatePull = {
  methodName: "CreatePull",
  service: PullService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_pull_pb.CreatePullRequest,
  responseType: ld_services_hostedapi_v1alpha1_pull_pb.Pull
};

PullService.ListPulls = {
  methodName: "ListPulls",
  service: PullService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_pull_pb.ListPullsRequest,
  responseType: ld_services_hostedapi_v1alpha1_pull_pb.ListPullsResponse
};

PullService.GetPullDetails = {
  methodName: "GetPullDetails",
  service: PullService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_pull_pb.GetPullDetailsRequest,
  responseType: ld_services_hostedapi_v1alpha1_pull_pb.GetPullDetailsResponse
};

PullService.GetPullPreview = {
  methodName: "GetPullPreview",
  service: PullService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_pull_pb.GetPullPreviewRequest,
  responseType: ld_services_hostedapi_v1alpha1_pull_pb.GetPullPreviewResponse
};

PullService.UpdatePull = {
  methodName: "UpdatePull",
  service: PullService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_pull_pb.UpdatePullRequest,
  responseType: ld_services_hostedapi_v1alpha1_pull_pb.Pull
};

PullService.CreatePullComment = {
  methodName: "CreatePullComment",
  service: PullService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_pull_pb.CreatePullCommentRequest,
  responseType: ld_services_hostedapi_v1alpha1_pull_pb.PullDiffSummary
};

PullService.UpdatePullComment = {
  methodName: "UpdatePullComment",
  service: PullService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_pull_pb.UpdatePullCommentRequest,
  responseType: ld_services_hostedapi_v1alpha1_pull_pb.PullDiffSummary
};

PullService.DeletePullComment = {
  methodName: "DeletePullComment",
  service: PullService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_pull_pb.DeletePullCommentRequest,
  responseType: ld_services_hostedapi_v1alpha1_pull_pb.PullDiffSummary
};

PullService.ListPullStatesForBranches = {
  methodName: "ListPullStatesForBranches",
  service: PullService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_pull_pb.ListPullStatesForBranchesRequest,
  responseType: ld_services_hostedapi_v1alpha1_pull_pb.ListPullStatesForBranchesResponse
};

exports.PullService = PullService;

function PullServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PullServiceClient.prototype.createPull = function createPull(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PullService.CreatePull, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PullServiceClient.prototype.listPulls = function listPulls(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PullService.ListPulls, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PullServiceClient.prototype.getPullDetails = function getPullDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PullService.GetPullDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PullServiceClient.prototype.getPullPreview = function getPullPreview(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PullService.GetPullPreview, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PullServiceClient.prototype.updatePull = function updatePull(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PullService.UpdatePull, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PullServiceClient.prototype.createPullComment = function createPullComment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PullService.CreatePullComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PullServiceClient.prototype.updatePullComment = function updatePullComment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PullService.UpdatePullComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PullServiceClient.prototype.deletePullComment = function deletePullComment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PullService.DeletePullComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PullServiceClient.prototype.listPullStatesForBranches = function listPullStatesForBranches(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PullService.ListPullStatesForBranches, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PullServiceClient = PullServiceClient;

