// package: ld.services.hosted.v1alpha1
// file: ld/services/hostedapi/v1alpha1/user.proto

var ld_services_hostedapi_v1alpha1_user_pb = require("../../../../ld/services/hostedapi/v1alpha1/user_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var UserService = (function () {
  function UserService() {}
  UserService.serviceName = "ld.services.hosted.v1alpha1.UserService";
  return UserService;
}());

UserService.GetUser = {
  methodName: "GetUser",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.GetUserRequest,
  responseType: ld_services_hostedapi_v1alpha1_user_pb.User
};

UserService.CreateUser = {
  methodName: "CreateUser",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.CreateUserRequest,
  responseType: ld_services_hostedapi_v1alpha1_user_pb.User
};

UserService.GetCurrentUser = {
  methodName: "GetCurrentUser",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.GetCurrentUserRequest,
  responseType: ld_services_hostedapi_v1alpha1_user_pb.User
};

UserService.UpdateUser = {
  methodName: "UpdateUser",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.UpdateUserRequest,
  responseType: ld_services_hostedapi_v1alpha1_user_pb.User
};

UserService.CreateEmailAddress = {
  methodName: "CreateEmailAddress",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.CreateEmailAddressRequest,
  responseType: ld_services_hostedapi_v1alpha1_user_pb.UserEmailAddress
};

UserService.DeleteEmailAddress = {
  methodName: "DeleteEmailAddress",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.DeleteEmailAddressRequest,
  responseType: google_protobuf_empty_pb.Empty
};

UserService.MakeEmailAddressPrimary = {
  methodName: "MakeEmailAddressPrimary",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.MakeEmailAddressPrimaryRequest,
  responseType: ld_services_hostedapi_v1alpha1_user_pb.UserEmailAddress
};

UserService.VerifyEmailAddress = {
  methodName: "VerifyEmailAddress",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.VerifyEmailAddressRequest,
  responseType: ld_services_hostedapi_v1alpha1_user_pb.UserEmailAddress
};

UserService.ResendVerifyEmail = {
  methodName: "ResendVerifyEmail",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.ResendVerifyEmailRequest,
  responseType: ld_services_hostedapi_v1alpha1_user_pb.ResendVerifyEmailResponse
};

UserService.UpdateUserPassword = {
  methodName: "UpdateUserPassword",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.UpdateUserPasswordRequest,
  responseType: ld_services_hostedapi_v1alpha1_user_pb.UpdateUserPasswordResponse
};

UserService.SendPasswordRecoveryEmail = {
  methodName: "SendPasswordRecoveryEmail",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.SendPasswordRecoveryEmailRequest,
  responseType: ld_services_hostedapi_v1alpha1_user_pb.SendPasswordRecoveryEmailResponse
};

UserService.UpdatePasswordFromToken = {
  methodName: "UpdatePasswordFromToken",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.UpdatePasswordFromTokenRequest,
  responseType: ld_services_hostedapi_v1alpha1_user_pb.UpdatePasswordFromTokenResponse
};

UserService.ListUserOrganizationMemberships = {
  methodName: "ListUserOrganizationMemberships",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.ListUserOrganizationMembershipsRequest,
  responseType: ld_services_hostedapi_v1alpha1_user_pb.ListUserOrganizationMembershipsResponse
};

UserService.SearchUsers = {
  methodName: "SearchUsers",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_user_pb.SearchUsersRequest,
  responseType: ld_services_hostedapi_v1alpha1_user_pb.SearchUsersResponse
};

exports.UserService = UserService;

function UserServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

UserServiceClient.prototype.getUser = function getUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.GetUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.createUser = function createUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.CreateUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.getCurrentUser = function getCurrentUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.GetCurrentUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.updateUser = function updateUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.UpdateUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.createEmailAddress = function createEmailAddress(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.CreateEmailAddress, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.deleteEmailAddress = function deleteEmailAddress(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.DeleteEmailAddress, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.makeEmailAddressPrimary = function makeEmailAddressPrimary(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.MakeEmailAddressPrimary, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.verifyEmailAddress = function verifyEmailAddress(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.VerifyEmailAddress, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.resendVerifyEmail = function resendVerifyEmail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.ResendVerifyEmail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.updateUserPassword = function updateUserPassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.UpdateUserPassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.sendPasswordRecoveryEmail = function sendPasswordRecoveryEmail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.SendPasswordRecoveryEmail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.updatePasswordFromToken = function updatePasswordFromToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.UpdatePasswordFromToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.listUserOrganizationMemberships = function listUserOrganizationMemberships(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.ListUserOrganizationMemberships, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.searchUsers = function searchUsers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.SearchUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.UserServiceClient = UserServiceClient;

