// source: ld/services/hostedapi/v1alpha1/deploymentteams.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_api_annotations_pb = require('../../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var ld_rpc_request_logging_pb = require('../../../../ld/rpc/request_logging_pb.js');
goog.object.extend(proto, ld_rpc_request_logging_pb);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.TeamPermissionLevel', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.displayName = 'proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.displayName = 'proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.displayName = 'proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest.displayName = 'proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
teamName: jspb.Message.getFieldWithDefault(msg, 2, ""),
permissionLevel: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission}
 */
proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission;
  return proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission}
 */
proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamName(value);
      break;
    case 3:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.TeamPermissionLevel} */ (reader.readEnum());
      msg.setPermissionLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTeamName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPermissionLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string team_name = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.prototype.getTeamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.prototype.setTeamName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TeamPermissionLevel permission_level = 3;
 * @return {!proto.ld.services.hosted.v1alpha1.TeamPermissionLevel}
 */
proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.prototype.getPermissionLevel = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.TeamPermissionLevel} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.TeamPermissionLevel} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.prototype.setPermissionLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
teamPermission: (f = msg.getTeamPermission()) && proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest;
  return proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 2:
      var value = new proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.deserializeBinaryFromReader);
      msg.setTeamPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTeamPermission();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeploymentTeamPermission team_permission = 2;
 * @return {?proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.prototype.getTeamPermission = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission, 2));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.prototype.setTeamPermission = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.prototype.clearTeamPermission = function() {
  return this.setTeamPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentTeamPermissionRequest.prototype.hasTeamPermission = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest;
  return proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
teamPermissionsList: jspb.Message.toObjectList(msg.getTeamPermissionsList(),
    proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.toObject, includeInstance),
nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse;
  return proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.deserializeBinaryFromReader);
      msg.addTeamPermissions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated DeploymentTeamPermission team_permissions = 1;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission>}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.prototype.getTeamPermissionsList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission, 1));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission>} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.prototype.setTeamPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.prototype.addTeamPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.prototype.clearTeamPermissionsList = function() {
  return this.setTeamPermissionsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListDeploymentTeamPermissionsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
teamPermission: (f = msg.getTeamPermission()) && proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest;
  return proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.deserializeBinaryFromReader);
      msg.setTeamPermission(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamPermission();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeploymentTeamPermission team_permission = 1;
 * @return {?proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.prototype.getTeamPermission = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission, 1));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.DeploymentTeamPermission|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.prototype.setTeamPermission = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.prototype.clearTeamPermission = function() {
  return this.setTeamPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.prototype.hasTeamPermission = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.UpdateDeploymentTeamPermissionRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest;
  return proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentTeamPermissionRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.TeamPermissionLevel = {
  TEAM_PERMISSION_LEVEL_UNSPECIFIED: 0,
  TEAM_PERMISSION_LEVEL_ADMIN: 1,
  TEAM_PERMISSION_LEVEL_READ: 2,
  TEAM_PERMISSION_LEVEL_WRITE: 3
};

goog.object.extend(exports, proto.ld.services.hosted.v1alpha1);
