import { useDelay } from "@dolthub/react-hooks";
import { useResendVerifyEmailMutation } from "@gen/graphql-types";
import { ApolloErrorType, SetApolloErrorType } from "@lib/errors/types";
import useCurrentUser from "../useCurrentUser";
import useMutation from "../useMutation";

type ReturnType = {
  onResend: () => Promise<void>;
  resendErr: ApolloErrorType;
  setResendErr: SetApolloErrorType;
  success: boolean;
  loading: boolean;
};

export default function useResendVerifyEmail(
  emailAddress?: string,
): ReturnType {
  const {
    mutateFn: resendVerifyEmail,
    err,
    setErr,
    loading,
  } = useMutation({
    hook: useResendVerifyEmailMutation,
  });
  const success = useDelay(4000);
  const { currentUser } = useCurrentUser();

  const onResend = async () => {
    if (!currentUser) return;
    const primaryEmail = currentUser.emailAddressesList.filter(
      e => e.isPrimary,
    );
    const { data } = await resendVerifyEmail({
      variables: {
        username: currentUser.username,
        emailAddress: emailAddress ?? primaryEmail[0].address,
      },
    });
    if (!data) return;
    success.start();
    setErr(undefined);
  };

  return {
    onResend,
    resendErr: err,
    setResendErr: setErr,
    success: success.active,
    loading,
  };
}
