import Link from "@components/links/Link";
import { useErrorHandlerContext } from "@contexts/errorHandler";
import { Button, Loader } from "@dolthub/react-components";
import useActiveRoute from "@hooks/useActiveRoute";
import useResendVerifyEmail from "@hooks/useResendVerifyEmail";
import { gqlConnectionLost, gqlEmailNotVerified } from "@lib/errors/graphql";
import { deployment } from "@lib/urls";
import {
  accessDeniedAdminErr,
  accessDeniedReaderErr,
  adminNotFoundErr,
  readerNotFoundErr,
} from "@lib/workbenchCommands";
import { useRouter } from "next/router";

// Must be child of ApolloProvider
export default function renderCustomErrorMsg(msg: string) {
  if (msg.includes(gqlEmailNotVerified)) {
    return <EmailVerificationMessage />;
  }
  if (msg.includes(gqlConnectionLost)) {
    return <ConnectionLostMessage errString={msg} />;
  }
  if (msg.includes("no authentication")) {
    return <NoAuthRedirect />;
  }

  // Database user perms error opens perms modal in DatabaseLayout
  if (
    msg.includes(accessDeniedReaderErr) ||
    msg.includes(accessDeniedAdminErr) ||
    msg.includes(readerNotFoundErr) ||
    msg.includes(adminNotFoundErr)
  ) {
    return <PermsModalOpen msg={msg} />;
  }

  return null;
}

function EmailVerificationMessage(props: { className?: string }) {
  const { onResend, resendErr, success } = useResendVerifyEmail();
  return (
    <div className={props.className} data-cy="error-msg">
      Please verify your email address to proceed.{" "}
      {success ? (
        <div>Email sent!</div>
      ) : (
        <Button.Link onClick={onResend}>Resend verification email.</Button.Link>
      )}
      {resendErr && <span> Error resending: {resendErr.message}</span>}
    </div>
  );
}

function NoAuthRedirect(props: { className?: string }) {
  const router = useRouter();

  router
    .push(`/signin?redirect=${encodeURIComponent(router.asPath)}`)
    .catch(console.error);

  return (
    <div aria-label="push-loader">
      <Loader loaded={false} />
      <div
        className={props.className}
        data-cy="error-msg"
        aria-label="error-msg"
      >
        No authentication. Redirecting to sign in page.
      </div>
    </div>
  );
}

function ConnectionLostMessage(props: {
  className?: string;
  errString: string;
}) {
  return (
    <div>
      <div
        className={props.className}
        data-cy="error-msg"
        aria-label="error-msg"
      >
        {props.errString}
      </div>
      <div>
        This error is often caused by a Dolt issue. Check your <LogsLink /> for
        more information.
      </div>
    </div>
  );
}

function LogsLink() {
  const active = useActiveRoute();
  if (!active.includes("/deployments/")) {
    return <span>deployment logs</span>;
  }
  const [, , ownerName, deploymentName] = active.split("/");
  return (
    <Link {...deployment({ ownerName, deploymentName, tab: "logs" })}>
      deployment logs
    </Link>
  );
}

function PermsModalOpen(props: { msg: string }) {
  const ehCtx = useErrorHandlerContext();

  if (typeof ehCtx !== "undefined" && !ehCtx.openPermsModal) {
    ehCtx.setOpenPermsModal(true);
    ehCtx.setErrMsg(props.msg);
  }
  return null;
}
