import "@components/AceEditor/ace-editor.css";
import "@components/util/KeyNav/index.css";
import Meta from "@components/util/Meta";
import { ErrorHandlerProvider } from "@contexts/errorHandler";
import { ServerConfigProvider, useServerConfig } from "@contexts/serverConfig";
import {
  ThemeProvider,
  hostedTailwindColorTheme,
} from "@dolthub/react-components";
import { FeatureGateProvider } from "@dolthub/react-contexts";
import { withApollo } from "@lib/apollo";
import { getIsDev } from "@lib/hostname";
import App from "next/app";
import Script from "next/script";
import { ReactNode } from "react";
import { SWRConfig } from "swr";
import "../styles/global.css";

// configure fetch for use with SWR
const fetcher = async (input: RequestInfo, init: RequestInit) => {
  const res = await fetch(input, init);
  if (!res.ok) {
    throw await res.json();
  }
  return res.json();
};

type Props = {
  children: ReactNode;
};

// Wrap FeatureGateProvider so it can access ServerConfig
function WrappedFeatureProvider({ children }: Props) {
  const { showGatedFeatures } = useServerConfig();
  const isDev = getIsDev();

  return (
    <FeatureGateProvider
      featureMap={
        new Map([
          ["test", false],
          ["doltgres", isDev],
        ])
      }
      showAll={showGatedFeatures}
    >
      {children}
    </FeatureGateProvider>
  );
}

export default class Hosted extends App {
  public render() {
    const { Component, pageProps, router } = this.props;

    // this.props.pageProps are the initial props fetched on a server side render.
    // The following keeps the pageProps updated with the client navigation.
    // This is necessary for pages that call getServerSideProps AND are routed to from within the app (i.e. router.push()).
    pageProps.params = {
      ...router.query,
    };

    const WrappedPage = withApollo()(Component);
    return (
      <>
        <Meta {...pageProps} />
        <Script
          key="stripe-js"
          id="stripe-js"
          src="https://js.stripe.com/v3/"
          async
        />
        <Script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/19923602.js"
        />
        <Script
          id="reo_script"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `!function(){var e,t,n;e="b625a773228c9b3",t=function(){Reo.init({clientID:"b625a773228c9b3"})},(n=document.createElement("script")).src="https://static.reo.dev/"+e+"/reo.js",n.async=!0,n.onload=t,document.head.appendChild(n)}();`,
          }}
          async
        />
        <Script
          id="reo_pi_script"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `!function(){var e,t,n;e="b625a773228c9b3",t=function(){ReoPi.init({pi_key:"5EN4M0H73ROM"})},(n=document.createElement("script")).src="https://static.reo.dev/"+e+"/reoPi.js",n.async=!0,n.onload=t,document.head.appendChild(n)}();`,
          }}
          async
        />
        <SWRConfig value={{ fetcher }}>
          <ServerConfigProvider>
            <ErrorHandlerProvider>
              <WrappedFeatureProvider>
                <ThemeProvider themeRGBOverrides={hostedTailwindColorTheme}>
                  <WrappedPage {...pageProps} />
                </ThemeProvider>
              </WrappedFeatureProvider>
            </ErrorHandlerProvider>
          </ServerConfigProvider>
        </SWRConfig>
      </>
    );
  }
}
