// package: ld.services.hosted.v1alpha1
// file: ld/services/hostedapi/v1alpha1/billing.proto

var ld_services_hostedapi_v1alpha1_billing_pb = require("../../../../ld/services/hostedapi/v1alpha1/billing_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BillingService = (function () {
  function BillingService() {}
  BillingService.serviceName = "ld.services.hosted.v1alpha1.BillingService";
  return BillingService;
}());

BillingService.CreateSubscription = {
  methodName: "CreateSubscription",
  service: BillingService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_billing_pb.CreateSubscriptionRequest,
  responseType: ld_services_hostedapi_v1alpha1_billing_pb.Subscription
};

BillingService.GetSubscription = {
  methodName: "GetSubscription",
  service: BillingService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_billing_pb.GetSubscriptionRequest,
  responseType: ld_services_hostedapi_v1alpha1_billing_pb.Subscription
};

BillingService.DeleteSubscription = {
  methodName: "DeleteSubscription",
  service: BillingService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_billing_pb.DeleteSubscriptionRequest,
  responseType: google_protobuf_empty_pb.Empty
};

BillingService.GetBillingPortalSession = {
  methodName: "GetBillingPortalSession",
  service: BillingService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_billing_pb.GetBillingPortalSessionRequest,
  responseType: ld_services_hostedapi_v1alpha1_billing_pb.GetBillingPortalSessionResponse
};

BillingService.GetItemizedBill = {
  methodName: "GetItemizedBill",
  service: BillingService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_billing_pb.GetItemizedBillRequest,
  responseType: ld_services_hostedapi_v1alpha1_billing_pb.GetItemizedBillResponse
};

exports.BillingService = BillingService;

function BillingServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BillingServiceClient.prototype.createSubscription = function createSubscription(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingService.CreateSubscription, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingServiceClient.prototype.getSubscription = function getSubscription(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingService.GetSubscription, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingServiceClient.prototype.deleteSubscription = function deleteSubscription(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingService.DeleteSubscription, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingServiceClient.prototype.getBillingPortalSession = function getBillingPortalSession(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingService.GetBillingPortalSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingServiceClient.prototype.getItemizedBill = function getItemizedBill(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingService.GetItemizedBill, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.BillingServiceClient = BillingServiceClient;

