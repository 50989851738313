// package: ld.services.hosted.v1alpha1
// file: ld/services/hostedapi/v1alpha1/incident.proto

var ld_services_hostedapi_v1alpha1_incident_pb = require("../../../../ld/services/hostedapi/v1alpha1/incident_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var IncidentService = (function () {
  function IncidentService() {}
  IncidentService.serviceName = "ld.services.hosted.v1alpha1.IncidentService";
  return IncidentService;
}());

IncidentService.CreateIncident = {
  methodName: "CreateIncident",
  service: IncidentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_incident_pb.CreateIncidentRequest,
  responseType: ld_services_hostedapi_v1alpha1_incident_pb.IncidentRecord
};

IncidentService.GetIncident = {
  methodName: "GetIncident",
  service: IncidentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_incident_pb.GetIncidentRequest,
  responseType: ld_services_hostedapi_v1alpha1_incident_pb.IncidentRecord
};

IncidentService.ListIncidents = {
  methodName: "ListIncidents",
  service: IncidentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_incident_pb.ListIncidentsRequest,
  responseType: ld_services_hostedapi_v1alpha1_incident_pb.ListIncidentsResponse
};

IncidentService.UpdateIncident = {
  methodName: "UpdateIncident",
  service: IncidentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_incident_pb.UpdateIncidentRequest,
  responseType: ld_services_hostedapi_v1alpha1_incident_pb.IncidentRecord
};

exports.IncidentService = IncidentService;

function IncidentServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

IncidentServiceClient.prototype.createIncident = function createIncident(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(IncidentService.CreateIncident, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IncidentServiceClient.prototype.getIncident = function getIncident(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(IncidentService.GetIncident, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IncidentServiceClient.prototype.listIncidents = function listIncidents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(IncidentService.ListIncidents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

IncidentServiceClient.prototype.updateIncident = function updateIncident(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(IncidentService.UpdateIncident, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.IncidentServiceClient = IncidentServiceClient;

