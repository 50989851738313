// source: ld/services/hostedapi/v1alpha1/incident.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var ld_rpc_request_logging_pb = require('../../../../ld/rpc/request_logging_pb.js');
goog.object.extend(proto, ld_rpc_request_logging_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateIncidentRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetIncidentRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.IncidentImpact', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.IncidentRecord', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.IncidentRecordSummary', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.IncidentStatus', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListIncidentsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.ListIncidentsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.IncidentRecord.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.IncidentRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.IncidentRecord.displayName = 'proto.ld.services.hosted.v1alpha1.IncidentRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.IncidentRecordSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.displayName = 'proto.ld.services.hosted.v1alpha1.IncidentRecordSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateIncidentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.displayName = 'proto.ld.services.hosted.v1alpha1.CreateIncidentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetIncidentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetIncidentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetIncidentRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetIncidentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListIncidentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.ListIncidentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.ListIncidentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.ListIncidentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.displayName = 'proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.IncidentRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
impact: jspb.Message.getFieldWithDefault(msg, 2, 0),
summary: jspb.Message.getFieldWithDefault(msg, 3, ""),
title: jspb.Message.getFieldWithDefault(msg, 4, ""),
status: jspb.Message.getFieldWithDefault(msg, 5, 0),
createdBy: jspb.Message.getFieldWithDefault(msg, 6, ""),
lastModifiedBy: jspb.Message.getFieldWithDefault(msg, 7, ""),
relatedDeploymentsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
lastModifiedAt: (f = msg.getLastModifiedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
resolvedAt: (f = msg.getResolvedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.IncidentRecord;
  return proto.ld.services.hosted.v1alpha1.IncidentRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.IncidentImpact} */ (reader.readEnum());
      msg.setImpact(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.IncidentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastModifiedBy(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addRelatedDeployments(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastModifiedAt(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setResolvedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.IncidentRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImpact();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLastModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRelatedDeploymentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastModifiedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getResolvedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IncidentImpact impact = 2;
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentImpact}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.getImpact = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.IncidentImpact} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentImpact} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.setImpact = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string summary = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional IncidentStatus status = 5;
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentStatus}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.getStatus = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.IncidentStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentStatus} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string created_by = 6;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string last_modified_by = 7;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.getLastModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.setLastModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string related_deployments = 11;
 * @return {!Array<string>}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.getRelatedDeploymentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.setRelatedDeploymentsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.addRelatedDeployments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.clearRelatedDeploymentsList = function() {
  return this.setRelatedDeploymentsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
*/
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp last_modified_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.getLastModifiedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
*/
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.setLastModifiedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.clearLastModifiedAt = function() {
  return this.setLastModifiedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.hasLastModifiedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp resolved_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.getResolvedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
*/
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.setResolvedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecord} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.clearResolvedAt = function() {
  return this.setResolvedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecord.prototype.hasResolvedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
impact: jspb.Message.getFieldWithDefault(msg, 2, 0),
title: jspb.Message.getFieldWithDefault(msg, 3, ""),
status: jspb.Message.getFieldWithDefault(msg, 4, 0),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
resolvedAt: (f = msg.getResolvedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.IncidentRecordSummary;
  return proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.IncidentImpact} */ (reader.readEnum());
      msg.setImpact(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.IncidentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setResolvedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImpact();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getResolvedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IncidentImpact impact = 2;
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentImpact}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.getImpact = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.IncidentImpact} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentImpact} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.setImpact = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional IncidentStatus status = 4;
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentStatus}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.getStatus = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.IncidentStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentStatus} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary} returns this
*/
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp resolved_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.getResolvedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary} returns this
*/
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.setResolvedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary} returns this
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.clearResolvedAt = function() {
  return this.setResolvedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.prototype.hasResolvedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateIncidentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
username: jspb.Message.getFieldWithDefault(msg, 1, ""),
deploymentsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
impact: jspb.Message.getFieldWithDefault(msg, 3, 0),
title: jspb.Message.getFieldWithDefault(msg, 4, ""),
summary: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateIncidentRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateIncidentRequest;
  return proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateIncidentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateIncidentRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeployments(value);
      break;
    case 3:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.IncidentImpact} */ (reader.readEnum());
      msg.setImpact(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateIncidentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeploymentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getImpact();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateIncidentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string deployments = 2;
 * @return {!Array<string>}
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.getDeploymentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateIncidentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.setDeploymentsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.CreateIncidentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.addDeployments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateIncidentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.clearDeploymentsList = function() {
  return this.setDeploymentsList([]);
};


/**
 * optional IncidentImpact impact = 3;
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentImpact}
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.getImpact = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.IncidentImpact} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentImpact} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateIncidentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.setImpact = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateIncidentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string summary = 5;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateIncidentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateIncidentRequest.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetIncidentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetIncidentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetIncidentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetIncidentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetIncidentRequest}
 */
proto.ld.services.hosted.v1alpha1.GetIncidentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetIncidentRequest;
  return proto.ld.services.hosted.v1alpha1.GetIncidentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetIncidentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetIncidentRequest}
 */
proto.ld.services.hosted.v1alpha1.GetIncidentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetIncidentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetIncidentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetIncidentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetIncidentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetIncidentRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetIncidentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetIncidentRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListIncidentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
username: jspb.Message.getFieldWithDefault(msg, 1, ""),
filterByImpact: jspb.Message.getFieldWithDefault(msg, 2, 0),
filterByStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
pageToken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListIncidentsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListIncidentsRequest;
  return proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListIncidentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListIncidentsRequest}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.IncidentImpact} */ (reader.readEnum());
      msg.setFilterByImpact(value);
      break;
    case 3:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.IncidentStatus} */ (reader.readEnum());
      msg.setFilterByStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListIncidentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilterByImpact();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFilterByStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListIncidentsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IncidentImpact filter_by_impact = 2;
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentImpact}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.prototype.getFilterByImpact = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.IncidentImpact} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentImpact} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListIncidentsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.prototype.setFilterByImpact = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional IncidentStatus filter_by_status = 3;
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentStatus}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.prototype.getFilterByStatus = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.IncidentStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentStatus} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListIncidentsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.prototype.setFilterByStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string page_token = 4;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListIncidentsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.ListIncidentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
incidentRecordSummariesList: jspb.Message.toObjectList(msg.getIncidentRecordSummariesList(),
    proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.toObject, includeInstance),
nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.ListIncidentsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.ListIncidentsResponse;
  return proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.ListIncidentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.ListIncidentsResponse}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.IncidentRecordSummary;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.deserializeBinaryFromReader);
      msg.addIncidentRecordSummaries(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.ListIncidentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncidentRecordSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.IncidentRecordSummary.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated IncidentRecordSummary incident_record_summaries = 1;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary>}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.prototype.getIncidentRecordSummariesList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.IncidentRecordSummary, 1));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary>} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListIncidentsResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.prototype.setIncidentRecordSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.IncidentRecordSummary}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.prototype.addIncidentRecordSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ld.services.hosted.v1alpha1.IncidentRecordSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.ListIncidentsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.prototype.clearIncidentRecordSummariesList = function() {
  return this.setIncidentRecordSummariesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.ListIncidentsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.ListIncidentsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
incident: (f = msg.getIncident()) && proto.ld.services.hosted.v1alpha1.IncidentRecord.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest;
  return proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest}
 */
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.IncidentRecord;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.IncidentRecord.deserializeBinaryFromReader);
      msg.setIncident(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncident();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.IncidentRecord.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional IncidentRecord incident = 1;
 * @return {?proto.ld.services.hosted.v1alpha1.IncidentRecord}
 */
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.prototype.getIncident = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.IncidentRecord} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.IncidentRecord, 1));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.IncidentRecord|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.prototype.setIncident = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.prototype.clearIncident = function() {
  return this.setIncident(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.prototype.hasIncident = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.UpdateIncidentRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.IncidentImpact = {
  INCIDENT_IMPACT_UNSPECIFIED: 0,
  INCIDENT_IMPACT_CRITICAL: 1,
  INCIDENT_IMPACT_HIGH: 2,
  INCIDENT_IMPACT_MEDIUM: 3,
  INCIDENT_IMPACT_LOW: 4,
  INCIDENT_IMPACT_NONE: 5
};

/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.IncidentStatus = {
  INCIDENT_STATUS_UNSPECIFIED: 0,
  INCIDENT_STATUS_OPEN: 1,
  INCIDENT_STATUS_RESOLVED: 2,
  INCIDENT_STATUS_CUSTOMER_CANCELED: 3
};

goog.object.extend(exports, proto.ld.services.hosted.v1alpha1);
