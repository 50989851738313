// package: ld.services.hosted.v1alpha1
// file: ld/services/hostedapi/v1alpha1/deploymentteams.proto

var ld_services_hostedapi_v1alpha1_deploymentteams_pb = require("../../../../ld/services/hostedapi/v1alpha1/deploymentteams_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var DeploymentTeamService = (function () {
  function DeploymentTeamService() {}
  DeploymentTeamService.serviceName = "ld.services.hosted.v1alpha1.DeploymentTeamService";
  return DeploymentTeamService;
}());

DeploymentTeamService.CreateDeploymentTeamPermission = {
  methodName: "CreateDeploymentTeamPermission",
  service: DeploymentTeamService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deploymentteams_pb.CreateDeploymentTeamPermissionRequest,
  responseType: ld_services_hostedapi_v1alpha1_deploymentteams_pb.DeploymentTeamPermission
};

DeploymentTeamService.ListDeploymentTeamPermissions = {
  methodName: "ListDeploymentTeamPermissions",
  service: DeploymentTeamService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deploymentteams_pb.ListDeploymentTeamPermissionsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deploymentteams_pb.ListDeploymentTeamPermissionsResponse
};

DeploymentTeamService.UpdateDeploymentTeamPermission = {
  methodName: "UpdateDeploymentTeamPermission",
  service: DeploymentTeamService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deploymentteams_pb.UpdateDeploymentTeamPermissionRequest,
  responseType: ld_services_hostedapi_v1alpha1_deploymentteams_pb.DeploymentTeamPermission
};

DeploymentTeamService.DeleteDeploymentTeamPermission = {
  methodName: "DeleteDeploymentTeamPermission",
  service: DeploymentTeamService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deploymentteams_pb.DeleteDeploymentTeamPermissionRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.DeploymentTeamService = DeploymentTeamService;

function DeploymentTeamServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

DeploymentTeamServiceClient.prototype.createDeploymentTeamPermission = function createDeploymentTeamPermission(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentTeamService.CreateDeploymentTeamPermission, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentTeamServiceClient.prototype.listDeploymentTeamPermissions = function listDeploymentTeamPermissions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentTeamService.ListDeploymentTeamPermissions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentTeamServiceClient.prototype.updateDeploymentTeamPermission = function updateDeploymentTeamPermission(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentTeamService.UpdateDeploymentTeamPermission, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentTeamServiceClient.prototype.deleteDeploymentTeamPermission = function deleteDeploymentTeamPermission(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentTeamService.DeleteDeploymentTeamPermission, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.DeploymentTeamServiceClient = DeploymentTeamServiceClient;

