// source: ld/services/hostedapi/v1alpha1/billing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_api_annotations_pb = require('../../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var ld_rpc_request_logging_pb = require('../../../../ld/rpc/request_logging_pb.js');
goog.object.extend(proto, ld_rpc_request_logging_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_money_pb = require('../../../../google/type/money_pb.js');
goog.object.extend(proto, google_type_money_pb);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.BillingItem', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.PaymentProvider', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.PaymentToken', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.Subscription', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.SubscriptionType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.Subscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.Subscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.Subscription.displayName = 'proto.ld.services.hosted.v1alpha1.Subscription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.PaymentToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.PaymentToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.PaymentToken.displayName = 'proto.ld.services.hosted.v1alpha1.PaymentToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.displayName = 'proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest.displayName = 'proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.BillingItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.BillingItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.BillingItem.displayName = 'proto.ld.services.hosted.v1alpha1.BillingItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.repeatedFields_, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.Subscription.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.Subscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.Subscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.Subscription.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.Subscription}
 */
proto.ld.services.hosted.v1alpha1.Subscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.Subscription;
  return proto.ld.services.hosted.v1alpha1.Subscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.Subscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.Subscription}
 */
proto.ld.services.hosted.v1alpha1.Subscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.SubscriptionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.Subscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.Subscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.Subscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.Subscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.Subscription.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.Subscription} returns this
 */
proto.ld.services.hosted.v1alpha1.Subscription.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SubscriptionType type = 2;
 * @return {!proto.ld.services.hosted.v1alpha1.SubscriptionType}
 */
proto.ld.services.hosted.v1alpha1.Subscription.prototype.getType = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.SubscriptionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.SubscriptionType} value
 * @return {!proto.ld.services.hosted.v1alpha1.Subscription} returns this
 */
proto.ld.services.hosted.v1alpha1.Subscription.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.PaymentToken.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.PaymentToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.PaymentToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.PaymentToken.toObject = function(includeInstance, msg) {
  var f, obj = {
provider: jspb.Message.getFieldWithDefault(msg, 1, 0),
token: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.PaymentToken}
 */
proto.ld.services.hosted.v1alpha1.PaymentToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.PaymentToken;
  return proto.ld.services.hosted.v1alpha1.PaymentToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.PaymentToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.PaymentToken}
 */
proto.ld.services.hosted.v1alpha1.PaymentToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ld.services.hosted.v1alpha1.PaymentProvider} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.PaymentToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.PaymentToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.PaymentToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.PaymentToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional PaymentProvider provider = 1;
 * @return {!proto.ld.services.hosted.v1alpha1.PaymentProvider}
 */
proto.ld.services.hosted.v1alpha1.PaymentToken.prototype.getProvider = function() {
  return /** @type {!proto.ld.services.hosted.v1alpha1.PaymentProvider} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.PaymentProvider} value
 * @return {!proto.ld.services.hosted.v1alpha1.PaymentToken} returns this
 */
proto.ld.services.hosted.v1alpha1.PaymentToken.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.PaymentToken.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.PaymentToken} returns this
 */
proto.ld.services.hosted.v1alpha1.PaymentToken.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
owner: jspb.Message.getFieldWithDefault(msg, 1, ""),
paymentToken: (f = msg.getPaymentToken()) && proto.ld.services.hosted.v1alpha1.PaymentToken.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest;
  return proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 2:
      var value = new proto.ld.services.hosted.v1alpha1.PaymentToken;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.PaymentToken.deserializeBinaryFromReader);
      msg.setPaymentToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ld.services.hosted.v1alpha1.PaymentToken.serializeBinaryToWriter
    );
  }
};


/**
 * optional string owner = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PaymentToken payment_token = 2;
 * @return {?proto.ld.services.hosted.v1alpha1.PaymentToken}
 */
proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.prototype.getPaymentToken = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.PaymentToken} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.PaymentToken, 2));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.PaymentToken|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.prototype.setPaymentToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.prototype.clearPaymentToken = function() {
  return this.setPaymentToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.CreateSubscriptionRequest.prototype.hasPaymentToken = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest}
 */
proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest;
  return proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest}
 */
proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetSubscriptionRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest}
 */
proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest;
  return proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest}
 */
proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.DeleteSubscriptionRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
subscriptionName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest}
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest;
  return proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest}
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest.prototype.getSubscriptionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionRequest.prototype.setSubscriptionName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
sessionUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse}
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse;
  return proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse}
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_url = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse.prototype.getSessionUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetBillingPortalSessionResponse.prototype.setSessionUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
owner: jspb.Message.getFieldWithDefault(msg, 1, ""),
start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
deploymentFilterList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest;
  return proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeploymentFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeploymentFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string owner = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.hasStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest} returns this
*/
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string deployment_filter = 4;
 * @return {!Array<string>}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.getDeploymentFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.setDeploymentFilterList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.addDeploymentFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillRequest.prototype.clearDeploymentFilterList = function() {
  return this.setDeploymentFilterList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.BillingItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.BillingItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.BillingItem.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
description: jspb.Message.getFieldWithDefault(msg, 2, ""),
hourlyRate: (f = msg.getHourlyRate()) && google_type_money_pb.Money.toObject(includeInstance, f),
cost: (f = msg.getCost()) && google_type_money_pb.Money.toObject(includeInstance, f),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
destroyAt: (f = msg.getDestroyAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.BillingItem}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.BillingItem;
  return proto.ld.services.hosted.v1alpha1.BillingItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.BillingItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.BillingItem}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new google_type_money_pb.Money;
      reader.readMessage(value,google_type_money_pb.Money.deserializeBinaryFromReader);
      msg.setHourlyRate(value);
      break;
    case 4:
      var value = new google_type_money_pb.Money;
      reader.readMessage(value,google_type_money_pb.Money.deserializeBinaryFromReader);
      msg.setCost(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDestroyAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.BillingItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.BillingItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.BillingItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHourlyRate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getCost();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDestroyAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.BillingItem} returns this
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.BillingItem} returns this
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.type.Money hourly_rate = 3;
 * @return {?proto.google.type.Money}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.getHourlyRate = function() {
  return /** @type{?proto.google.type.Money} */ (
    jspb.Message.getWrapperField(this, google_type_money_pb.Money, 3));
};


/**
 * @param {?proto.google.type.Money|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.BillingItem} returns this
*/
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.setHourlyRate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.BillingItem} returns this
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.clearHourlyRate = function() {
  return this.setHourlyRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.hasHourlyRate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.Money cost = 4;
 * @return {?proto.google.type.Money}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.getCost = function() {
  return /** @type{?proto.google.type.Money} */ (
    jspb.Message.getWrapperField(this, google_type_money_pb.Money, 4));
};


/**
 * @param {?proto.google.type.Money|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.BillingItem} returns this
*/
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.setCost = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.BillingItem} returns this
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.clearCost = function() {
  return this.setCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.hasCost = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.BillingItem} returns this
*/
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.BillingItem} returns this
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp destroy_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.getDestroyAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.BillingItem} returns this
*/
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.setDestroyAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.BillingItem} returns this
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.clearDestroyAt = function() {
  return this.setDestroyAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.BillingItem.prototype.hasDestroyAt = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
hours: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.ld.services.hosted.v1alpha1.BillingItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse;
  return proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHours(value);
      break;
    case 4:
      var value = new proto.ld.services.hosted.v1alpha1.BillingItem;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.BillingItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHours();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ld.services.hosted.v1alpha1.BillingItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp start = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp end = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float hours = 3;
 * @return {number}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.getHours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.setHours = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated BillingItem line_items = 4;
 * @return {!Array<!proto.ld.services.hosted.v1alpha1.BillingItem>}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.ld.services.hosted.v1alpha1.BillingItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ld.services.hosted.v1alpha1.BillingItem, 4));
};


/**
 * @param {!Array<!proto.ld.services.hosted.v1alpha1.BillingItem>} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ld.services.hosted.v1alpha1.BillingItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ld.services.hosted.v1alpha1.BillingItem}
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ld.services.hosted.v1alpha1.BillingItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetItemizedBillResponse.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.SubscriptionType = {
  SUBSCRIPTION_TYPE_UNSPECIFIED: 0,
  SUBSCRIPTION_TYPE_ACTIVE: 1
};

/**
 * @enum {number}
 */
proto.ld.services.hosted.v1alpha1.PaymentProvider = {
  PAYMENT_PROVIDER_UNSPECIFIED: 0,
  PAYMENT_PROVIDER_STRIPE: 1
};

goog.object.extend(exports, proto.ld.services.hosted.v1alpha1);
