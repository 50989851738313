import {
  CurrentUserFragment,
  IdentityProvider,
  UseCurrentUserQueryHookResult,
  useUseCurrentUserQuery,
} from "@gen/graphql-types";

type ReturnType = Omit<UseCurrentUserQueryHookResult, "data"> & {
  currentUser: CurrentUserFragment | undefined;
  isSamlUser: boolean;
};

export default function useCurrentUser(): ReturnType {
  const { data, ...res } = useUseCurrentUserQuery();
  const currentUser = data?.currentUser;
  return {
    ...res,
    isSamlUser: currentUser?.provider === IdentityProvider.Saml,
    currentUser,
  };
}
