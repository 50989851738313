import { useServerConfig } from "@contexts/serverConfig";
import Script from "next/script";
import { ReactNode } from "react";

// GoogleWrapper needs to wrap all pages, but be a child of
// ServerConfigProvider. It should only be used once in `lib/apollo.tsx`
export function GoogleWrapper(props: { children: ReactNode }) {
  const { gaTrackingID } = useServerConfig();

  return (
    <>
      <Script
        src="https://accounts.google.com/gsi/client"
        id="gsi-client"
        async
        defer
      />
      {gaTrackingID && <AnalyticsTags gaTrackingId={gaTrackingID} />}
      {props.children}
    </>
  );
}

function AnalyticsTags({ gaTrackingId }: { gaTrackingId: string }) {
  return (
    <>
      <Script async src="https://www.google-analytics.com/analytics.js" />
      <Script
        id="window-ga"
        dangerouslySetInnerHTML={{
          __html: `
          window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
        `,
        }}
      />
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
      />
      <Script
        id="window-data-layer"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gaTrackingId}');
          `,
        }}
      />
    </>
  );
}
