export const gqlEmailAddressMustMatch = "Email address must match";
export const gqlUserNotFoundForUsername =
  "A username was specified but the corresponding user was not found";
export const gqlMutateOrg = `pq: update or delete on table "organizations" violates foreign key constraint "metering_owner_organization_id_fk_fkey" on table "metering"`;
export const gqlNoAuthentication = `no authentication`;
export const gqlUserNotFound = "User not found";
export const gqlEmailNotVerified = "Email address not verified";
export const gqlTableNotFound = `no such table`;
export const gqlNoEditPerms = "cannot edit database";
export const gqlDepNotFound = "no such deployment";
export const gqlNoPullFoundErr = "no such pull";
export const gqlNoRefFoundErr = "no such ref in database";
export const gqlPullHasConflicts = "Merge conflict detected";
export const gqlCommandDeniedToReader =
  "command denied to user 'hosted-ui-reader'@'%'";
export const gqlErrorPrimaryKeyChange = "primary key set change";
export const gqlConnectionLost = "Connection lost";
