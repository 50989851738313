import useActiveRoute from "@hooks/useActiveRoute";
import { colors } from "@lib/tailwind";
import Head from "next/head";

type Props = {
  title?: string;
  description?: string;
  noIndex?: boolean;
};

// Defines metadata about the website, including what information shows in
// the card when the site is linked
export default function Meta(props: Props) {
  const title = props.title ?? `Hosted DoltDB`;
  const description =
    props.description ??
    "Hosted Dolt is AWS for Dolt, the world's first and only version controlled SQL database.";
  const route = useActiveRoute();
  const url = `${process.env.NEXT_PUBLIC_BASE_URL}${route}`;
  const image = `${process.env.NEXT_PUBLIC_BASE_URL}/hosted-preview.png`;
  const space = colors.space["700"];

  return (
    <Head>
      <title>{title}</title>
      {/* this disables the auto zoom/page shift on mobile when clicking on selectors */}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {props.noIndex && <meta name="robots" content="noindex" />}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />

      <meta property="og:description" content={description} />

      <meta property="og:image" content={image} />
      <meta property="og:site_name" content="Hosted DoltDB" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@dolthub" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <link rel="canonical" href={url} />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        content={space}
      />
      <link rel="icon" href="/favicon.ico" />
      <meta name="msapplication-TileColor" content={space} />
      <meta name="theme-color" content={space} />
    </Head>
  );
}
