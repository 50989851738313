import { ErrorMsgProvider } from "@dolthub/react-components";
import { improveErrorMsg } from "@lib/errors/helpers";
import { ReactNode } from "react";
import renderCustomErrorMsg from "./CustomErrorMsg";
import { GoogleWrapper } from "./GoogleWrapper";

type Props = {
  children?: ReactNode;
};

// Includes scripts or providers that must wrap every page but utilize apollo
// and therefore must be a child of ApolloProvider
export default function InnerApolloWrapper(props: Props) {
  return (
    <GoogleWrapper>
      <ErrorMsgProvider
        improveErrorMsgFn={improveErrorMsg}
        renderDifferentComp={renderCustomErrorMsg}
      >
        {props.children}
      </ErrorMsgProvider>
    </GoogleWrapper>
  );
}
