import { createCustomContext } from "@dolthub/react-contexts";
import { useContextWithError } from "@dolthub/react-hooks";
import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from "react";

type ErrorContextType = {
  openPermsModal: boolean;
  setOpenPermsModal: Dispatch<SetStateAction<boolean>>;
  errMsg: string;
  setErrMsg: Dispatch<SetStateAction<string>>;
};

export const ErrorContext =
  createCustomContext<ErrorContextType>("ErrorContext");

type Props = {
  children: ReactNode;
};

export function ErrorHandlerProvider(props: Props) {
  const [openPermsModal, setOpenPermsModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const value = useMemo(() => {
    return { openPermsModal, setOpenPermsModal, errMsg, setErrMsg };
  }, [openPermsModal, setOpenPermsModal, errMsg, setErrMsg]);

  return (
    <ErrorContext.Provider value={value}>
      {props.children}
    </ErrorContext.Provider>
  );
}

export function useErrorHandlerContext(): ErrorContextType {
  return useContextWithError(ErrorContext);
}
