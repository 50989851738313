import { ApolloError } from "@apollo/client";
import {
  gqlEmailAddressMustMatch,
  gqlMutateOrg,
  gqlUserNotFoundForUsername,
} from "./graphql";
import { ApolloErrorType, ErrorType } from "./types";

export const emailAddressInvalid = "Error: Invalid email address.";
export const cannotDeleteOrg =
  "Error: Cannot delete organization. If you need to delete this organization, please email us at dolt-interest@dolthub.com.";
export const userNotFound = "Error: User not found";

export function improveErrorMsg(message: string): string {
  switch (message) {
    case gqlUserNotFoundForUsername:
      return userNotFound;
    case gqlMutateOrg:
      return cannotDeleteOrg;
    case gqlEmailAddressMustMatch:
      return emailAddressInvalid;
    case "":
      return "Error message empty";
    default:
      return message;
  }
}

export function errorMatches(
  errString: string,
  err?: ApolloErrorType,
): boolean {
  return !!err?.message.match(errString);
}

export function errorMsgMatches(errString: string, errMsg?: string): boolean {
  return !!errMsg?.match(errString);
}

export function handleCaughtError(err: unknown, cb: (e: ErrorType) => void) {
  if (err instanceof Error) {
    cb(err);
  } else {
    cb(new Error(String(err)));
  }
}

export function getCaughtApolloError(err: unknown): ApolloErrorType {
  if (err instanceof ApolloError) {
    return err;
  }
  if (err instanceof Error) {
    return err;
  }
  return new Error(String(err));
}

export function handleCaughtApolloError(
  err: unknown,
  cb: (e: ApolloErrorType) => void,
) {
  cb(getCaughtApolloError(err));
}
