// package: ld.services.hosted.v1alpha1
// file: ld/services/hostedapi/v1alpha1/deployments.proto

var ld_services_hostedapi_v1alpha1_deployments_pb = require("../../../../ld/services/hostedapi/v1alpha1/deployments_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var DeploymentService = (function () {
  function DeploymentService() {}
  DeploymentService.serviceName = "ld.services.hosted.v1alpha1.DeploymentService";
  return DeploymentService;
}());

DeploymentService.GetSupportedZones = {
  methodName: "GetSupportedZones",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.GetSupportedZonesRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.GetSupportedZonesResponse
};

DeploymentService.GetAvailableInstanceTypes = {
  methodName: "GetAvailableInstanceTypes",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.GetAvailableInstanceTypesRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.GetAvailableInstanceTypesResponse
};

DeploymentService.GetStorageOptions = {
  methodName: "GetStorageOptions",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.GetStorageOptionsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.GetStorageOptionsResponse
};

DeploymentService.CalcHourlyCost = {
  methodName: "CalcHourlyCost",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.CalcHourlyCostRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.CalcHourlyCostResponse
};

DeploymentService.CalcCost = {
  methodName: "CalcCost",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.CalcCostRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.CalcCostResponse
};

DeploymentService.CreateDeployment = {
  methodName: "CreateDeployment",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.CreateDeploymentRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.CreateDeploymentResponse
};

DeploymentService.DestroyDeployment = {
  methodName: "DestroyDeployment",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.DestroyDeploymentRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.DestroyDeploymentResponse
};

DeploymentService.ListDeployments = {
  methodName: "ListDeployments",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.ListDeploymentsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.ListDeploymentsResponse
};

DeploymentService.ListDeploymentInstances = {
  methodName: "ListDeploymentInstances",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.ListDeploymentInstancesRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.ListDeploymentInstancesResponse
};

DeploymentService.ListUserDeployments = {
  methodName: "ListUserDeployments",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.ListUserDeploymentsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.ListUserDeploymentsResponse
};

DeploymentService.GetDeployment = {
  methodName: "GetDeployment",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.GetDeploymentRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.GetDeploymentResponse
};

DeploymentService.UpdateDeployment = {
  methodName: "UpdateDeployment",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.UpdateDeploymentRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.Deployment
};

DeploymentService.GetLogs = {
  methodName: "GetLogs",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.GetLogsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.GetLogsResponse
};

DeploymentService.GetDashboards = {
  methodName: "GetDashboards",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.GetDashboardsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.GetDashboardsResponse
};

DeploymentService.GetGraphs = {
  methodName: "GetGraphs",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.GetGraphsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.GetGraphsResponse
};

DeploymentService.GetGraphData = {
  methodName: "GetGraphData",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.GetGraphDataRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.GetGraphDataResponse
};

DeploymentService.CreateDeploymentCollaborator = {
  methodName: "CreateDeploymentCollaborator",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.CreateDeploymentCollaboratorRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.DeploymentCollaborator
};

DeploymentService.ListDeploymentCollaborators = {
  methodName: "ListDeploymentCollaborators",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.ListDeploymentCollaboratorsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.ListDeploymentCollaboratorsResponse
};

DeploymentService.UpdateDeploymentCollaborator = {
  methodName: "UpdateDeploymentCollaborator",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.UpdateDeploymentCollaboratorRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.DeploymentCollaborator
};

DeploymentService.DeleteDeploymentCollaborator = {
  methodName: "DeleteDeploymentCollaborator",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.DeleteDeploymentCollaboratorRequest,
  responseType: google_protobuf_empty_pb.Empty
};

DeploymentService.CreateDatabaseCredentials = {
  methodName: "CreateDatabaseCredentials",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.CreateDatabaseCredentialsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.CreateDatabaseCredentialsResponse
};

DeploymentService.ListDeploymentsWithBackups = {
  methodName: "ListDeploymentsWithBackups",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.ListDeploymentsWithBackupsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.ListDeploymentWithBackupsResponse
};

DeploymentService.GetSupportedOverrides = {
  methodName: "GetSupportedOverrides",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.GetSupportedOverridesRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.GetSupportedOverridesResponse
};

DeploymentService.GetConfigOverrides = {
  methodName: "GetConfigOverrides",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.GetConfigOverridesRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.GetConfigOverridesResponse
};

DeploymentService.SetConfigOverrides = {
  methodName: "SetConfigOverrides",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.SetConfigOverridesRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.SetConfigOverridesResponse
};

DeploymentService.GetServiceWindows = {
  methodName: "GetServiceWindows",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.GetServiceWindowsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.GetServiceWindowsResponse
};

DeploymentService.UpdateServiceWindow = {
  methodName: "UpdateServiceWindow",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.UpdateServiceWindowRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.ServiceWindow
};

DeploymentService.ListDoltVersions = {
  methodName: "ListDoltVersions",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.ListDoltVersionsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.ListDoltVersionsResponse
};

DeploymentService.AddInstanceToDeployment = {
  methodName: "AddInstanceToDeployment",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.AddInstanceToDeploymentRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.AddInstanceToDeploymentResponse
};

DeploymentService.DeleteInstanceFromDeployment = {
  methodName: "DeleteInstanceFromDeployment",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.DeleteInstanceFromDeploymentRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.DeleteInstanceFromDeploymentResponse
};

DeploymentService.RebootInstance = {
  methodName: "RebootInstance",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.RebootInstanceRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.RebootInstanceResponse
};

DeploymentService.RestartDolt = {
  methodName: "RestartDolt",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.RestartDoltRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.RestartDoltResponse
};

DeploymentService.CreateBackup = {
  methodName: "CreateBackup",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.CreateBackupRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.CreateBackupResponse
};

DeploymentService.UpdateDolt = {
  methodName: "UpdateDolt",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.UpdateDoltRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.UpdateDoltResponse
};

DeploymentService.UpdateExposeRemotesapiEndpoint = {
  methodName: "UpdateExposeRemotesapiEndpoint",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.UpdateExposeRemotesapiEndpointRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.UpdateExposeRemotesapiEndpointResponse
};

DeploymentService.UpdateConfigOverrides = {
  methodName: "UpdateConfigOverrides",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.UpdateConfigOverridesRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.UpdateConfigOverridesResponse
};

DeploymentService.UpdateWorkbenchSettings = {
  methodName: "UpdateWorkbenchSettings",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.UpdateWorkbenchSettingsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.WorkbenchSettings
};

DeploymentService.GetWorkbenchSettings = {
  methodName: "GetWorkbenchSettings",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.GetWorkbenchSettingsRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.WorkbenchSettings
};

DeploymentService.ChangePrimary = {
  methodName: "ChangePrimary",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.ChangePrimaryRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.ChangePrimaryResponse
};

DeploymentService.CreateDoltVersion = {
  methodName: "CreateDoltVersion",
  service: DeploymentService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_deployments_pb.CreateDoltVersionRequest,
  responseType: ld_services_hostedapi_v1alpha1_deployments_pb.CreateDoltVersionResponse
};

exports.DeploymentService = DeploymentService;

function DeploymentServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

DeploymentServiceClient.prototype.getSupportedZones = function getSupportedZones(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.GetSupportedZones, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.getAvailableInstanceTypes = function getAvailableInstanceTypes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.GetAvailableInstanceTypes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.getStorageOptions = function getStorageOptions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.GetStorageOptions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.calcHourlyCost = function calcHourlyCost(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.CalcHourlyCost, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.calcCost = function calcCost(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.CalcCost, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.createDeployment = function createDeployment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.CreateDeployment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.destroyDeployment = function destroyDeployment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.DestroyDeployment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.listDeployments = function listDeployments(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.ListDeployments, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.listDeploymentInstances = function listDeploymentInstances(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.ListDeploymentInstances, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.listUserDeployments = function listUserDeployments(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.ListUserDeployments, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.getDeployment = function getDeployment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.GetDeployment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.updateDeployment = function updateDeployment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.UpdateDeployment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.getLogs = function getLogs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.GetLogs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.getDashboards = function getDashboards(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.GetDashboards, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.getGraphs = function getGraphs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.GetGraphs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.getGraphData = function getGraphData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.GetGraphData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.createDeploymentCollaborator = function createDeploymentCollaborator(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.CreateDeploymentCollaborator, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.listDeploymentCollaborators = function listDeploymentCollaborators(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.ListDeploymentCollaborators, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.updateDeploymentCollaborator = function updateDeploymentCollaborator(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.UpdateDeploymentCollaborator, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.deleteDeploymentCollaborator = function deleteDeploymentCollaborator(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.DeleteDeploymentCollaborator, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.createDatabaseCredentials = function createDatabaseCredentials(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.CreateDatabaseCredentials, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.listDeploymentsWithBackups = function listDeploymentsWithBackups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.ListDeploymentsWithBackups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.getSupportedOverrides = function getSupportedOverrides(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.GetSupportedOverrides, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.getConfigOverrides = function getConfigOverrides(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.GetConfigOverrides, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.setConfigOverrides = function setConfigOverrides(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.SetConfigOverrides, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.getServiceWindows = function getServiceWindows(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.GetServiceWindows, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.updateServiceWindow = function updateServiceWindow(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.UpdateServiceWindow, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.listDoltVersions = function listDoltVersions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.ListDoltVersions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.addInstanceToDeployment = function addInstanceToDeployment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.AddInstanceToDeployment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.deleteInstanceFromDeployment = function deleteInstanceFromDeployment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.DeleteInstanceFromDeployment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.rebootInstance = function rebootInstance(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.RebootInstance, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.restartDolt = function restartDolt(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.RestartDolt, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.createBackup = function createBackup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.CreateBackup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.updateDolt = function updateDolt(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.UpdateDolt, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.updateExposeRemotesapiEndpoint = function updateExposeRemotesapiEndpoint(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.UpdateExposeRemotesapiEndpoint, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.updateConfigOverrides = function updateConfigOverrides(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.UpdateConfigOverrides, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.updateWorkbenchSettings = function updateWorkbenchSettings(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.UpdateWorkbenchSettings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.getWorkbenchSettings = function getWorkbenchSettings(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.GetWorkbenchSettings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.changePrimary = function changePrimary(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.ChangePrimary, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeploymentServiceClient.prototype.createDoltVersion = function createDoltVersion(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeploymentService.CreateDoltVersion, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.DeploymentServiceClient = DeploymentServiceClient;

