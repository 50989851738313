import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function useActiveRoute(initialRoute = ""): string {
  const [activeRoute, setActiveRoute] = useState(initialRoute);
  const router = useRouter();
  useEffect(() => {
    setActiveRoute(router.asPath);
  }, [router.asPath]);
  return activeRoute;
}
