// package: ld.services.hosted.v1alpha1
// file: ld/services/hostedapi/v1alpha1/session.proto

var ld_services_hostedapi_v1alpha1_session_pb = require("../../../../ld/services/hostedapi/v1alpha1/session_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var SessionService = (function () {
  function SessionService() {}
  SessionService.serviceName = "ld.services.hosted.v1alpha1.SessionService";
  return SessionService;
}());

SessionService.CreateSession = {
  methodName: "CreateSession",
  service: SessionService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_session_pb.CreateSessionRequest,
  responseType: ld_services_hostedapi_v1alpha1_session_pb.CreateSessionResponse
};

SessionService.DeleteCurrentSession = {
  methodName: "DeleteCurrentSession",
  service: SessionService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_session_pb.DeleteCurrentSessionRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.SessionService = SessionService;

function SessionServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

SessionServiceClient.prototype.createSession = function createSession(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SessionService.CreateSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SessionServiceClient.prototype.deleteCurrentSession = function deleteCurrentSession(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SessionService.DeleteCurrentSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.SessionServiceClient = SessionServiceClient;

