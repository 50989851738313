// source: ld/services/hostedapi/v1alpha1/deploymentcredentials.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var ld_rpc_request_logging_pb = require('../../../../ld/rpc/request_logging_pb.js');
goog.object.extend(proto, ld_rpc_request_logging_pb);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest', null, global);
goog.exportSymbol('proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.displayName = 'proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest.displayName = 'proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse.displayName = 'proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
keyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
publicKey: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials}
 */
proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials;
  return proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials}
 */
proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKeyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key_id = 2;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.prototype.getKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.prototype.setKeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string public_key = 3;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials} returns this
 */
proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest;
  return proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentDoltCredentials: (f = msg.getDeploymentDoltCredentials()) && proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse;
  return proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials;
      reader.readMessage(value,proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.deserializeBinaryFromReader);
      msg.setDeploymentDoltCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentDoltCredentials();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeploymentDoltCredentials deployment_dolt_credentials = 1;
 * @return {?proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.prototype.getDeploymentDoltCredentials = function() {
  return /** @type{?proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials} */ (
    jspb.Message.getWrapperField(this, proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials, 1));
};


/**
 * @param {?proto.ld.services.hosted.v1alpha1.DeploymentDoltCredentials|undefined} value
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse} returns this
*/
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.prototype.setDeploymentDoltCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse} returns this
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.prototype.clearDeploymentDoltCredentials = function() {
  return this.setDeploymentDoltCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ld.services.hosted.v1alpha1.GetDeploymentDoltCredentialsResponse.prototype.hasDeploymentDoltCredentials = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest;
  return proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse;
  return proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.CreateDeploymentDoltCredentialsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest;
  return proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.DeleteDeploymentDoltCredentialsRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deploymentName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest}
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest;
  return proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest}
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deployment_name = 1;
 * @return {string}
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest.prototype.getDeploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest} returns this
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsRequest.prototype.setDeploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse}
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse;
  return proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse}
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ld.services.hosted.v1alpha1.RerollDeploymentDoltCredentialsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.ld.services.hosted.v1alpha1);
