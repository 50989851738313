import { DeploymentFragment } from "@gen/graphql-types";
import { DeploymentUtils } from "@hosted/resource-utils";

export const uiReader = "hosted-ui-reader";
export const uiAdmin = "hosted-ui-admin";

export const readerNotFoundErr = `User not found '${uiReader}'`;
export const adminNotFoundErr = `User not found '${uiAdmin}'`;
export const accessDeniedReaderErr = `Access denied for user '${uiReader}'`;
export const accessDeniedAdminErr = `Access denied for user '${uiAdmin}'`;

export const createUserStmt = (d: DeploymentFragment, user: string) =>
  `CREATE USER '${user}'@'%' IDENTIFIED WITH authentication_dolt_jwt AS 'jwks=doltdb_hosted,aud=${DeploymentUtils.rn.fromParams(
    d,
  )},sub=${user}';`;

export const grantStmt = (isAdmin: boolean) =>
  `GRANT ${isAdmin ? "ALL" : "SELECT"} ON *.* TO '${
    isAdmin ? uiAdmin : uiReader
  }'@'%';`;

export const dropUserStmt = (user: string) => `DROP USER '${user}'@'%';`;
