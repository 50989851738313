// package: ld.services.hosted.v1alpha1
// file: ld/services/hostedapi/v1alpha1/organization.proto

var ld_services_hostedapi_v1alpha1_organization_pb = require("../../../../ld/services/hostedapi/v1alpha1/organization_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var OrganizationService = (function () {
  function OrganizationService() {}
  OrganizationService.serviceName = "ld.services.hosted.v1alpha1.OrganizationService";
  return OrganizationService;
}());

OrganizationService.CreateOrganization = {
  methodName: "CreateOrganization",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.CreateOrganizationRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.Organization
};

OrganizationService.GetOrganization = {
  methodName: "GetOrganization",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.GetOrganizationRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.Organization
};

OrganizationService.UpdateOrganization = {
  methodName: "UpdateOrganization",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.UpdateOrganizationRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.Organization
};

OrganizationService.DeleteOrganization = {
  methodName: "DeleteOrganization",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.DeleteOrganizationRequest,
  responseType: google_protobuf_empty_pb.Empty
};

OrganizationService.CreateOrganizationMember = {
  methodName: "CreateOrganizationMember",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.CreateOrganizationMemberRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.OrganizationMember
};

OrganizationService.ListOrganizationMembers = {
  methodName: "ListOrganizationMembers",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.ListOrganizationMembersRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.ListOrganizationMembersResponse
};

OrganizationService.DeleteOrganizationMember = {
  methodName: "DeleteOrganizationMember",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.DeleteOrganizationMemberRequest,
  responseType: google_protobuf_empty_pb.Empty
};

OrganizationService.UpdateOrganizationMember = {
  methodName: "UpdateOrganizationMember",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.UpdateOrganizationMemberRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.OrganizationMember
};

OrganizationService.CreateTeam = {
  methodName: "CreateTeam",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.CreateTeamRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.Team
};

OrganizationService.GetTeam = {
  methodName: "GetTeam",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.GetTeamRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.Team
};

OrganizationService.UpdateTeam = {
  methodName: "UpdateTeam",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.UpdateTeamRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.Team
};

OrganizationService.DeleteTeam = {
  methodName: "DeleteTeam",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.DeleteTeamRequest,
  responseType: google_protobuf_empty_pb.Empty
};

OrganizationService.ListTeams = {
  methodName: "ListTeams",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.ListTeamsRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.ListTeamsResponse
};

OrganizationService.CreateTeamMember = {
  methodName: "CreateTeamMember",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.CreateTeamMemberRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.TeamMember
};

OrganizationService.ListTeamMembers = {
  methodName: "ListTeamMembers",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.ListTeamMembersRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.ListTeamMembersResponse
};

OrganizationService.DeleteTeamMember = {
  methodName: "DeleteTeamMember",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.DeleteTeamMemberRequest,
  responseType: google_protobuf_empty_pb.Empty
};

OrganizationService.UpdateTeamMember = {
  methodName: "UpdateTeamMember",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.UpdateTeamMemberRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.TeamMember
};

OrganizationService.CreateSAMLIdentityProvider = {
  methodName: "CreateSAMLIdentityProvider",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.CreateSAMLIdentityProviderRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.OrganizationSAMLIdentityProvider
};

OrganizationService.GetSAMLIdentityProvider = {
  methodName: "GetSAMLIdentityProvider",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.GetSAMLIdentityProviderRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.GetSAMLIdentityProviderResponse
};

OrganizationService.DeleteSAMLIdentityProvider = {
  methodName: "DeleteSAMLIdentityProvider",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.DeleteSAMLIdentityProviderRequest,
  responseType: google_protobuf_empty_pb.Empty
};

OrganizationService.UpdateSAMLIdentityProvider = {
  methodName: "UpdateSAMLIdentityProvider",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.UpdateSAMLIdentityProviderRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.OrganizationSAMLIdentityProvider
};

OrganizationService.GetSAMLAuthnRequest = {
  methodName: "GetSAMLAuthnRequest",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_organization_pb.GetSAMLAuthnRequestRequest,
  responseType: ld_services_hostedapi_v1alpha1_organization_pb.GetSAMLAuthnRequestResponse
};

exports.OrganizationService = OrganizationService;

function OrganizationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

OrganizationServiceClient.prototype.createOrganization = function createOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.CreateOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.getOrganization = function getOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.GetOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.updateOrganization = function updateOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.UpdateOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.deleteOrganization = function deleteOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.DeleteOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.createOrganizationMember = function createOrganizationMember(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.CreateOrganizationMember, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.listOrganizationMembers = function listOrganizationMembers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.ListOrganizationMembers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.deleteOrganizationMember = function deleteOrganizationMember(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.DeleteOrganizationMember, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.updateOrganizationMember = function updateOrganizationMember(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.UpdateOrganizationMember, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.createTeam = function createTeam(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.CreateTeam, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.getTeam = function getTeam(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.GetTeam, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.updateTeam = function updateTeam(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.UpdateTeam, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.deleteTeam = function deleteTeam(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.DeleteTeam, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.listTeams = function listTeams(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.ListTeams, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.createTeamMember = function createTeamMember(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.CreateTeamMember, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.listTeamMembers = function listTeamMembers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.ListTeamMembers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.deleteTeamMember = function deleteTeamMember(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.DeleteTeamMember, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.updateTeamMember = function updateTeamMember(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.UpdateTeamMember, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.createSAMLIdentityProvider = function createSAMLIdentityProvider(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.CreateSAMLIdentityProvider, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.getSAMLIdentityProvider = function getSAMLIdentityProvider(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.GetSAMLIdentityProvider, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.deleteSAMLIdentityProvider = function deleteSAMLIdentityProvider(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.DeleteSAMLIdentityProvider, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.updateSAMLIdentityProvider = function updateSAMLIdentityProvider(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.UpdateSAMLIdentityProvider, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.getSAMLAuthnRequest = function getSAMLAuthnRequest(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.GetSAMLAuthnRequest, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.OrganizationServiceClient = OrganizationServiceClient;

