// package: ld.services.hosted.v1alpha1
// file: ld/services/hostedapi/v1alpha1/backups.proto

var ld_services_hostedapi_v1alpha1_backups_pb = require("../../../../ld/services/hostedapi/v1alpha1/backups_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BackupService = (function () {
  function BackupService() {}
  BackupService.serviceName = "ld.services.hosted.v1alpha1.BackupService";
  return BackupService;
}());

BackupService.ListBackups = {
  methodName: "ListBackups",
  service: BackupService,
  requestStream: false,
  responseStream: false,
  requestType: ld_services_hostedapi_v1alpha1_backups_pb.ListBackupsRequest,
  responseType: ld_services_hostedapi_v1alpha1_backups_pb.ListBackupsResponse
};

exports.BackupService = BackupService;

function BackupServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BackupServiceClient.prototype.listBackups = function listBackups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BackupService.ListBackups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.BackupServiceClient = BackupServiceClient;

